import * as type from "../constants/actions/ActionTypes";
import * as cogLib from "../libs/cognitoLib";
import { Auth } from 'aws-amplify';

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');

export const getPermissionsFromCognitoToken = () => {
    return new Promise(async (resolve, reject) => {
    	try {
        	const user = await Auth.currentAuthenticatedUser();
        	const attributes = user.attributes;
        	resolve({
            	userPermissions: attributes?.['custom:access']?.split(","),
        		// TODO: Remove above default values
        		// TODO: Some of these will need to be transformed from what azure is sending us.
            	username: user.username,
            	role: attributes['custom:type'], // What kind of user is this ( STORE, REGIONAL_MANAGER, AREA_SALES_MANAGER, ADMIN )
            	...(attributes['custom:mystores'] && {myStores: attributes['custom:mystores']?.split(",")}), // A list of strings. Each string is a store id that this user is allowed to access.
            	...(attributes['custom:store'] && {store: attributes['custom:store']}), // Store ID
            	...(attributes['custom:store_view_code'] && {store_view_code: attributes['custom:store_view_code'] === 'Universal Store' ? 'default' : 'perfect_stranger'}), // 'default' if this is a US store else 'perfect_stranger'
            	...(attributes['custom:hardware'] && {hardware: attributes['custom:hardware'].split(',')}) // Contains store hardware info for network printers.
        	});
        } catch (e) {
        	reject(e);
		}
    });
}

export const fetchAuthorization = () => {
    return async () => {
        return getPermissionsFromCognitoToken().then(data => {
            return data;
        })
    }
};


export const loadCognitoInfo = (cogInfo) => {
   return (
       {
           type: type.LOAD_COGNITO_INFO,
           identityId:cogInfo.id,
           cognitoInfo : cogInfo.attributes
       }
   )
};


export const authorizeUser = (user) => {
    return(
        {
            type: type.AUTHORISE_USER,
            user:user
        }
    )
};
export const authorizeStore = (store) => {
    return(
        {
            type: type.AUTHORISE_STORE,
            store:store
        }
    )
};

export const loginUser = (verifyEmail = true) => {
    return (
        {
            type:type.LOGIN_USER,
            status: "LOGGED_IN",
            email : verifyEmail
        }
    )
};

export const updateRoleStatus = (status) => {
    return (
        {
            type:type.UPDATE_ROLE_USER,
            payload: status
        }
    )
};

export const idleUser = () => {
    return (
        {
            type:type.IDLE_USER,
            status: "IDLE"
        }
    )
};

/**
 * retrieves token and makes a backend call that returns the users permissions and username to store in store state.
 * @returns {Function}
 */
export function authorize (){
    return (dispatch,getState) =>{
        dispatch(fetchAuthorization()).then(response => {
            if (response.role === "STORE"){
                dispatch(authorizeStore(response));
            } else {
                dispatch(authorizeUser(response));
            }
            let userId = getState().authState.username;
            cogLib.fetchCognitoInfo(userId).then(response => {
                dispatch(loadCognitoInfo(response));
            });
        })
    }
}
