import * as type from "../constants/actions/ActionTypes";
import * as lambda from "../actions/LambdaCalls";
export const createNewRole = () => {
    return(
        {
            type: type.CREATE_ROLE,
        }
    )
};

export const loadRoles = async() => {
    return await lambda.loadRoles();
};
// load roles depending on user using the add page and what store has been selected
export const loadSingleStoreRoles = async(store,userRole) => {
    let singleStore = await lambda.getSingleStore(store.value);
    let storeRoles = [];
    let roleObject = await lambda.getRole(userRole);
    let managedRoles = roleObject.manage;
    for( let role in singleStore.responsibilities[0]){
        if(managedRoles.includes(role.toUpperCase())) {
            let tempRole = await lambda.getRole(role.toUpperCase());
            storeRoles.push({value:tempRole.roleId,label:tempRole.roleName});
        }
    }
    return storeRoles;
};
export const postRole =async (roleData) =>{
    return await lambda.createRole(roleData);
};

export const loadRoleFromStores = async(store) => {
    let singleStore = await lambda.getSingleStore(store);
    let storeRoles = [];
    for( let role in singleStore.responsibilities[0]){
            let tempRole = await lambda.getRole(role.toUpperCase());
            storeRoles.push({value:tempRole.roleId,label:tempRole.roleName});
    }
    return storeRoles;
};

export const loadRolesFromStore = async(store) => {
    let singleStore = await lambda.getSingleStore(store);
    let storeRoles = [];
    for( let role in singleStore.responsibilities[0]){
        let tempRole = await lambda.getRole(role.toUpperCase());
        storeRoles.push(tempRole?.roleId);
    }
    return storeRoles;
};
