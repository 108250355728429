import * as type from "../constants/actions/ActionTypes";
import * as lambda from "../actions/LambdaCalls";
import * as notification from '../actions/NotificationActions'
/**
 * Takes a parameter true/false to inform frontend if loading is in process
 * @param loading
 * @returns {{type: string, fetching: *}}
 */
const isLoading = (loading) => {
    return(
        {
            type: type.ADMIN_IS_LOADING,
            loading: loading
        }
    )
}

export const fetchStores = () => {
    return(
        {
            type: type.FETCH_STORES
        }
    )
};


export const loadingStores = (loading) => {
    return(
        {
            type: type.LOADING_STORES,
            loading: loading
        }
    )
};


/**
 * Adds store to state.stores
 * @param store
 * @returns {{type: string, payload: *}}
 */
export const addStore = store => {
    return(
        {
            type: type.ADD_STORE,
            payload: store
        }
    )


};
/**
 * Adds store to state.stores
 * @param store
 * @returns {{type: string, payload: *}}
 */
export const setCurrentStore = store => {
    return(
        {
            type: type.SET_CURRENT_STORE,
            payload: store
        }
    )
};


/**
 *  retrieves all stores, and gets information about all stores also adds MANAGERS/ASM names to object,
 *  finally adding to the stores state

 * @returns {Function}
 */

export function getMembers(members){

}
/*returns name and userid of searched store members*/
export const getMembersOfStore = async (storeId,role) => {
    let store = await lambda.getSingleStore(storeId);
    let members = [];
    if(store.responsibilities[0][role]){
        if(Array.isArray(store.responsibilities[0][role])){
            for (let member of store.responsibilities[0][role]){
                let memberInfo =await getMemberInfo(member);
                if(memberInfo.error){
                }else {
                    let name = memberInfo.fName ? memberInfo.fName.data : null  + ' ' +memberInfo.lName ? memberInfo.lName.data : null;
                    members.push({value:member,label:name});
                }
            }
        }
    }
    return members;
};
export const fetchStoress =async () =>{
    return await lambda.getStores();
};

export function getStores() {
    return (dispatch) => {
        dispatch(fetchStores());
        dispatch(loadingStores(true));
        let storesAdded =0;
        lambda.getStores().then(async function(data) {
            for (let i in data){
                storesAdded ++;
                let store = await lambda.getSingleStore(data[i].value);
                if(store.responsibilities[0] !== []){
                    let members = store.responsibilities[0];
                    // for (let role of members){
                    //     console.log(role);
                    // }
                }

                // let managers = store.responsibilities[0].manager;
                // let asms = store.responsibilities[0].asm;
                // let members = store.responsibilities[0].team_member;
                // let managersWithInfo = [];
                // let asmsWithInfo = [];
                // let membersWithInfo = [];
                // for(let i in managers){
                //     let user = await getMemberInfo(managers[i]);
                //     let member ={name:user.fName.data+" "+user.lName.data, userId:user.userId};
                //     managersWithInfo.push(member);
                // }
                // for (let i in asms){
                //     let user = await getMemberInfo(asms[i]);
                //     let member ={name:user.fName.data+" "+user.lName.data, userId:user.userId};
                //     asmsWithInfo.push(member);
                // }
                // for (let i in members){
                //     let user = await getMemberInfo(members[i]);
                //     let member ={name:user.fName.data+" "+user.lName.data, userId:user.userId};
                //     membersWithInfo.push(member);
                // }
                dispatch(addStore(store));

            }
            if(storesAdded === data.length){
                //dispatch false for loading states, to tell frontend all stores have been loaded
                dispatch(loadingStores(false));

            }
        })
            .catch((err) => console.log('err:', err))
    }
}

/**
 * get member information for any userId
 * @param userId
 * @returns {Promise<any>}
 */
export function getMemberInfo(userId) {
    return new Promise(
        (resolve, reject) => {
            let user = lambda.getUserInfo(userId);
            // create object of members info being returned, can be changed in future
            resolve(user);
        })

}

export function getSingleStore(storeId){
    return (dispatch) => {
        lambda.getSingleStore(storeId).then(result =>{
            dispatch(setCurrentStore(result));
        });
    }
}

export const fetchAllStores = () => {
    return () => {
        return lambda.getStores().then(result =>{
            return result
        });
    }
}


export const fetchSingleStore =(storeId) => {
    return () => {
        return lambda.getSingleStore(storeId).then(response =>{
            return response;
        });
    }
};

export const fetchUserRoleInformation =(userId) => {
    return () => {
        return lambda.getUserRole(userId).then(response =>{
            return response;
        });
    }
}

export const updateStore = (store) => {
    return () => {
        return lambda.updateStore(store).then(response =>{
           return response;
        });
    }
};

function checkForRole(store,role){
    console.log(store);
    let roles = store.responsibilities[0];
    for(let r in roles){
        if(r === role){
            return true;
        }
    }
    return false;
}

function addNewRoles (store,roles){
    return dispatch => {
        let currResponsiblities ={};
        // if store already has roles in it , check if role exists or does not exist.
        let added= 0;
        let duplicates =0;
        let found =0;
        if(store.responsibilities[0]){
            // takes current respoibilsites and stores them
            currResponsiblities = store.responsibilities[0];
            if(!Array.isArray(roles)){
                for (let r in currResponsiblities){

                    if(r === roles){
                        found =1
                    }
                }
                if (found ===1){
                    console.log('role already exists');
                    dispatch(notification.warningNotification(roles +' already exists as a role'));
                    duplicates++;
                }else {
                    currResponsiblities[roles] = [];
                    console.log('added role since it doesnt exist');
                    added ++;
                }
            }else{
                for (let role of roles) {
                    found =0;
                    for (let res in currResponsiblities){
                        console.log(res);
                        if(res === role){
                            found =1
                        }
                    }
                    if (found ===1){
                        console.log('role already exists');
                        dispatch(notification.warningNotification(role +'already exists as a role'));
                        duplicates++;
                    }else {
                        currResponsiblities[role] = [];
                        console.log('added role since it doesnt exist');
                        added ++;
                    }
                }
            }

            // replaces old responsibilties with the new ones.
            store.responsibilities[0] = currResponsiblities;
            console.log(store.responsibilities)
        }else {
            if(!Array.isArray(roles)){
                currResponsiblities[roles] = [];
                console.log('added role since it doesnt exist');
            }else {
                for (let role of roles) {
                    currResponsiblities[role] = [];
                    added ++;
                    console.log('added role since it doesnt exist');
                }
            }
            store.responsibilities.push(currResponsiblities);
        }
        dispatch(setCurrentStore(store));
    }
    }

export function updateStoreRoles (store, roles) {
    return (dispatch, getState) => {
        dispatch(addNewRoles(store,roles));
        let newStore = getState().storesState.store;
        return dispatch(updateStore(newStore)).then(response => {
            if(response){
                console.log(response);
                if(response.updated){
                    dispatch(notification.successNotification('role created'));
                }
                else {
                    dispatch(notification.errorNotification('role not created as no changes were found'))
                }
            }else {
                dispatch(notification.errorNotification('role not created'))
            }
        });
    }
};

function addNewMember(store,user,role){
    return (dispatch,getState) => {
        if(checkForRole(store,role)){
            let newRole = store.responsibilities[0][role];
            if(!newRole.includes(user)){
               newRole.push(user);
                store.responsibilities[0][role] = newRole;
            }

            dispatch(setCurrentStore(store));
        }else {
            dispatch(addNewRoles(store,role));
            let newStore = getState().storesState.store;
            let newRole = newStore.responsibilities[0][role];
            if(!newRole.includes(user)){
                newRole.push(user);
                store.responsibilities[0][role] = newRole;
            }
            dispatch(setCurrentStore(store));
        }
    }
}

function deleteMember(store,user,role){
    return (dispatch,getState) => {
        if(checkForRole(store,role)){
            let newRole = store.responsibilities[0][role];
            let index = newRole.indexOf(user);
            if(index !== -1){
                newRole.splice(index, 1);
                store.responsibilities[0][role] = newRole;
            }

            dispatch(setCurrentStore(store));
        }
    }
}

export function updateStoreMembers(stores,userId,role) {
    return (dispatch, getState) => {
        if(Array.isArray(stores)){
            stores.forEach(function(store){
                dispatch(addNewMember(store, userId, role));
                let newStore = getState().storesState.store;
                return dispatch(updateStore(newStore)).then(response => {
                    if (response) {
                        if(response.updated){
                            dispatch(notification.successNotification('Member added to store '+store.storeId));
                        }else {
                            dispatch(notification.errorNotification('No new members to add in ' +store.storeId))
                        }
                    }else {
                        dispatch(notification.errorNotification('Member not added'))
                    }
                });

            });
        }else {
            dispatch(addNewMember(stores, userId, role));
            let newStore = getState().storesState.store;
            return dispatch(updateStore(newStore)).then(response => {
                console.log(response);
                if(response){
                    if(response.updated){
                        dispatch(notification.successNotification('Member added to store '+stores.storeId));
                    }else {
                        dispatch(notification.errorNotification('No new members to add in ' +stores.storeId))
                    }
                }else {
                    dispatch(notification.errorNotification('Member not added'))
                }
            });
        }
    }
}

export function removeMemberFromStores(stores,userId,role) {

    return (dispatch, getState) => {
        let storesAdded =0;
        if(Array.isArray(stores)){
            stores.forEach(function(store){
                dispatch(deleteMember(store, userId, role));
                let newStore = getState().storesState.store;
                return dispatch(updateStore(newStore)).then(response => {
                    if (response) {
                        if(response.updated){
                            dispatch(notification.successNotification('Member added to store '+store.storeId));
                        }else {
                            dispatch(notification.errorNotification('No new members to add in ' +store.storeId))
                        }
                    }else {
                        dispatch(notification.errorNotification('Member not added'))
                    }
                });

            });
        }else {
            dispatch(deleteMember(stores, userId, role));
            let newStore = getState().storesState.store;
            return dispatch(updateStore(newStore)).then(response => {
                if(response){
                    if(response.updated){
                        dispatch(notification.successNotification('Member deleted from store '+stores.storeId));
                    }else {
                        dispatch(notification.errorNotification('No member to delete ' +stores.storeId))
                    }
                }else {
                    dispatch(notification.errorNotification('Member not deleted'))
                }
            });
        }
    }
}
function hasChanges(o1, o2) {
    return JSON.stringify(o1) != JSON.stringify(o2);
}

export function getAllStores() {
    return (dispatch) => {
        return dispatch(fetchAllStores()).then(response => {
            return response;
        });
    }
}

/**
 * non Redux functions (Aux function)
 * @param userId
 * @param role
 * @returns {Promise<[]|Function>}
 */
export function getStoresByUser (){
    return (_, getState) => {
        return getState().authState.myStores;
    };
};

