import * as type from "../../constants/actions/CheckoutActionTypes";
import * as api from "./CheckoutCalls"
import {errorNotification} from "../NotificationActions";
import {loading as catalogLoading} from "../catalog/CatalogActions";
import * as jsPDF from "jspdf";
import JsBarcode from "jsbarcode";
import unistoreLogo from "../../images/store-logo.png"
import * as notification from "../NotificationActions";
import * as shippingApi from "../ShippingCalls";
import { MageQL } from '../../graphql/MagentoClient';
import { AppSyncQL } from "../../graphql/AppSyncClient";
import {
    ADD_TO_CART,
    CREATE_NEW_CART,
    SET_ADDRESS,
    REMOVE_FROM_CART,
    SET_SHIPPING_METHOD,
    SET_PAYMENT_METHOD,
    PLACE_ORDER
} from "../../graphql/MagentoMutations";
import { GET_CART, GET_PRODUCT } from "../../graphql/MagentoQueries";
import { NEW_STD_ORDER } from "../../graphql/AppSyncMutations";


const loading = (loading) => {
    return (
        {
            type:type.LOADING_CHECKOUT,
            loading:loading
        }
    )
};

/**
 * Adds product to cart, as there is no qty attached we will attach the qty 1 by default since we only
 * allow 1 qty to be added at a time
 * @param item
 * @return {{item, type: string}}
 */
const addToCheckoutCart = (item) => {
    let updated_item = {...item};
    updated_item.qty = 1;
    return (
        {
            type:type.ADD_TO_CART,
            item: updated_item,
            sku: updated_item.selected_size.sku
        }
    )
};

export const addToCart = (item) => {
    return (dispatch,getState) => {
        dispatch(loading(true));
        dispatch(catalogLoading(true));
        dispatch(addToCheckoutCart(item));
        let cart_id = getState().checkoutState.cart_id;
        if (!cart_id) {
            dispatch(getNewMagentoCartID(getState().authState.store_view_code)).then(response => {
                cart_id = response.data.createEmptyCart;
                dispatch(updateCartId(cart_id));
                addFromCatalogToCart(cart_id, item, dispatch, getState);
            }).catch(
                error => {
                    console.error(error);
                    dispatch(notification.errorNotification(
                        "Couldn't create a new cart. Please contact Dev Support and try again later"
                    ));
                    dispatch(clearOrder());
                }
            );
        } else {
            addFromCatalogToCart(cart_id, item, dispatch, getState);
        }
    }
};

const addFromCatalogToCart = (cart_id, item, dispatch, getState, qty=1) => {
    let item_count = getQuantityOfCartItems(getState().checkoutState.cart_items);
    dispatch(getProductAttributesUID(item.sku, getState().authState.store_view_code)).then(simples => {
        let UID = getUIDFromSimples(simples.data.products, item.sku, item.selected_size.simple);
        dispatch(addToMagentoCart(cart_id, item, UID, qty, getState().authState.store_view_code)).then(
            mage_cart => {
                let cart = mage_cart.data.addProductsToCart.cart;
                updateCart(cart, dispatch);
                if (item_count !== getQuantityOfCartItems(getState().checkoutState.cart_items)) {
                    dispatch(notification.errorNotification("Sorry, this size is out of stock online."));
                }
            }
        ).catch(
            error => {
                console.error(error);
                dispatch(notification.errorNotification(error.message));
                dispatch(getMagentoCart(cart_id, getState().authState.store_view_code)).then((mage_cart) => {
                    let cart = mage_cart.data.cart;
                    updateCart(cart, dispatch);
                }).catch((error) => {
                    console.error(error);
                    dispatch(notification.errorNotification("Something went really wrong. Try starting again."));
                    dispatch(loading(false));
                    dispatch(catalogLoading(false));
                    dispatch(clearOrder());
                });
            }
        );
    }).catch(
        error => {
            console.error(error);
            dispatch(notification.errorNotification(error.message));
            dispatch(getMagentoCart(cart_id, getState().authState.store_view_code)).then((mage_cart) => {
                let cart = mage_cart.data.cart;
                updateCart(cart, dispatch);
            }).catch((error) => {
                console.error(error);
                dispatch(notification.errorNotification("Something went really wrong. Try starting again."));
                dispatch(loading(false));
                dispatch(catalogLoading(false));
                dispatch(clearOrder());
            });
        }
    );
}

const getQuantityOfCartItems = (cart_items) => {
    let qty = 0;
    for (let key of Object.keys(cart_items)) {
        if (cart_items[key].product_name) {
            qty += cart_items[key].qty;
        }
    }
    return qty;
}

const getUIDFromSimples = (simples, config_sku, simple_sku) => {
    for (let item of simples.items) {
        console.log(item);
        if (item.sku === config_sku) {
            for (let simple of item.variants) {
                if (simple.product.sku === simple_sku) {
                    return simple.attributes[0].uid;
                }
            }
        }
    }
    throw new Error("Couldn't find the UID for the simple product");
}

const updateCart = (mage_cart, dispatch) => {
    dispatch(updateCartItems(mage_cart.items));
    dispatch(updateTotal(mage_cart.prices.grand_total.value));
    dispatch(updateDiscounts(mage_cart.serialized_discount_amounts));
    dispatch(loading(false));
    dispatch(catalogLoading(false));
}

const updateCartId = (cart_id) => {
    return (
        {
            type:type.UPDATE_CART_ID,
            cart_id
        }
    )
}

export const updateCartItems = (cart_items) => {
    return (
        {
            type:type.UPDATE_CART_ITEMS,
            cart_items
        }
    )
}

export const updateStage = (stage) => {
    return (
        {
            type:type.UPDATE_STAGE,
            stage
        }
    )
}

/**
 * TODO redo total maths
 * @param price
 * @param update_type 'add' 'remove'
 * @return {{price, type: string}}
 */
export const updateTotal = (price) => {
    return (
        {
            type: type.UPDATE_TOTAL,
            price: price
        }
    )
}

export const updateDiscounts = (discounts) => {
    return (
        {
            type: type.UPDATE_DISCOUNTS,
            discounts
        }
    )
}

export const updateSize = (item, size) => {
    return (dispatch, getState) => {
        let cart_id = getState().checkoutState.cart_id;
        dispatch(loading(true));
        dispatch(catalogLoading(true));
        dispatch(removeFromCheckoutCart(item));
        dispatch(removeFromMagentoCart(cart_id, item, getState().authState.store_view_code)).then(() => {
            item.selected_size = size;
            dispatch(addToCheckoutCart(item));
            addFromCatalogToCart(cart_id, item, dispatch, getState, item.qty);
        });
    }
}

const updateCheckoutSize = (sku, size) => {
    return (
        {
            type: type.UPDATE_SIZE,
            sku,
            size
        }
    )
}

export const removeFromCart = (item) => {
    return (dispatch, getState) => {
        let cart_id = getState().checkoutState.cart_id;
        dispatch(loading(true));
        dispatch(catalogLoading(true));
        if (!cart_id) {
            dispatch(notification.errorNotification("Something went really wrong. Try starting again."));
            dispatch(clearOrder());
        } else {
            dispatch(removeFromCheckoutCart(item));
            dispatch(removeFromMagentoCart(cart_id, item, getState().authState.store_view_code)).then(
                (mage_cart) => {
                    let cart = mage_cart.data.removeItemFromCart.cart;
                    updateCart(cart, dispatch);
                }
            );
        }
    }
}

const removeFromCheckoutCart = (item) => {
    return (
        {
            type: type.REMOVE_FROM_CART,
            item
        }
    )
}

export const updateCustomerDetails = (customer_details) => {
    return (
        {
            type:type.UPDATE_CUSTOMER_DETAILS,
            customer_details
        }
    )
}

export const updateAddressDetails = (address_details) => {
    return (
        {
            type:type.UPDATE_ADDRESS_DETAILS,
            address_details
        }
    )
}

export const updateMageDetails = (customer_details, shipping_address) => {
    return (dispatch,getState) => {
        let checkout_state = getState().checkoutState;
        let cart_id = checkout_state.cart_id;
        let cart_items = checkout_state.cart_items;

        if (cart_id && Object.keys(cart_items).length > 0) {
            dispatch(loading(true));
            dispatch(
              setMagentoAddress(cart_id, customer_details, shipping_address,
                getState().authState.store_view_code)).then((shipping_info) => {
                dispatch(setAvailableMethods(
                  shipping_info.data.setShippingAddressesOnCart.cart.shipping_addresses[0].available_shipping_methods))
                dispatch(loading(false));
                dispatch(updateStage(2));
            })
        } else {
            dispatch(notification.errorNotification("Please add some items to the cart before continuing"));
            dispatch(loading(false));
            dispatch(catalogLoading(false));
        }

    }
}

const setAvailableMethods = (methods) => {
    return (
        {
            type:type.SET_AVAILABLE_METHODS,
            methods
        }
    )
}

export const updateShippingMethod = (shipping_method) => {
    return (dispatch, getState) => {
        let cart_id = getState().checkoutState.cart_id;
        dispatch(loading(true));
        dispatch(updateShippingCheckoutMethod(shipping_method));
        dispatch(setMagentoShippingMethod(cart_id, shipping_method.carrier_code, shipping_method.method_code, getState().authState.store_view_code)).then(() => {
            dispatch(getMagentoCart(cart_id, getState().authState.store_view_code)).then((mage_cart) => {
                let cart = mage_cart.data.cart;
                updateCart(cart, dispatch);
            }).catch((error) => {
                console.error(error);
                dispatch(notification.errorNotification("Something went really wrong. Try starting again."));
                dispatch(clearOrder());
            });
        });
    }
}

const updateShippingCheckoutMethod = (shipping_method) => {
    return (
        {
            type:type.UPDATE_SHIPPING_METHOD,
            shipping_method
        }
    )
}

const updateConfirmation = (confirmation) => {
    return (
        {
            type:type.UPDATE_CONFIRMATION,
            confirmation
        }
    )
}

const updateReceiptId = (receipt_id) => {
    return (
        {
            type:type.UPDATE_RECEIPT_ID,
            receipt_id
        }
    )
}
const updateFulfiller = (fulfiller) => {
    return (
        {
            type:type.UPDATE_FULFILLER,
            fulfiller
        }
    )
}

export const clearOrder = () => {
    return (
        {
            type:type.CLEAR_STATE,
        }
    )
}

const createStoreOrder = (order) => {
    return () => {
        return api.createOrder(order).then(response => {
            return response;
        })
    }
}
const fetchFulfillerData = (store,futuraId) => {
    return () => {
        return shippingApi.getFulfillerData(store,futuraId).then(response => {
            return response;
        })
    }
};

export function getFulfillerData  (futuraId) {
    return(dispatch,getState) => {
        dispatch(loading(true));
        let storeId = getState().authState.store;

        dispatch(fetchFulfillerData(storeId, futuraId)).then(response => {
            if (!response.status) {
                dispatch(notification.errorNotification(response.error + '! Please try again'));
                dispatch(loading(false));
            } else {
                dispatch(updateFulfiller(response.fulfillerInfo.futuraId));
                dispatch(loading(false));
            }
        }).catch((e) => {
            console.log(e);
            dispatch(loading(false));
        })
    }
}

export function copyBarcode (barcode) {
    return (dispatch) => {
        dispatch(notification.successNotification(`Copied barcode to clipboard - ${barcode}`))
    }
}

export function confirmOrderDetails () {
    return (dispatch,getState) => {
        dispatch(loading(true))
        const checkout = getState().checkoutState
        dispatch(setMagentoPaymentMethod(checkout.cart_id, getState().authState.store_view_code)).then(() => {
            dispatch(placeMagentoOrder(checkout.cart_id, getState().authState.store_view_code)).then((order_info) => {
                dispatch(placeAppSyncOrder(getState(), order_info.data.placeOrder.order.order_number)).then(() => {
                    dispatch(updateConfirmation(true));
                    let store_name = getState().authState.store_view_code === "perfect_stranger" ? "PERFECT STRANGER": "UNIVERSAL STORE";
                    dispatch(updateReceiptId(order_info.data.placeOrder.order.order_number));
                    let builder = printReceipt(order_info.data.placeOrder.order.order_number, checkout.cart_items, checkout.total, store_name);
                    let address = `http://${getState().authState.hardware[0]}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
                    let ePosDev = new window.epson.ePOSPrint(address);
                    ePosDev.onreceive = function (res) {
                        //When the printing is not successful, display a message
                        if (!res.success) {alert('A print error occurred');}
                    }
                    ePosDev.send(builder.toString());
                    dispatch(updateStage(4));
                    dispatch(loading(false));
                });
            });
        }).catch(error => {
            dispatch(loading(false))
            errorNotification('Something Went Wrong! Please try again')
            console.log(error);
        })
    }
}

/**
 * Function builds a receipt & returns a builder object which will be passed to an ePosDevice object
 * @param order_id
 * @param cart_items
 * @param cart_total
 * @param store_name
 * @returns object
 */
export function printReceipt(order_id,cart_items,cart_total,store_name="UNIVERSAL STORE"){
    let builder = new window.epson.ePOSBuilder();
    let receipt_total = (cart_total).toFixed(2);

    builder.addTextStyle(false,false,true,builder.COLOR_3);
    builder.addTextAlign(builder.ALIGN_CENTER);
    builder.addTextDouble(true,true)
    builder.addTextLineSpace(150);
    builder.addText(`${store_name}\n`);
    builder.addTextDouble(false,false)
    builder.addTextStyle(false,false,false,builder.COLOR_NONE);
    builder.addTextFont(builder.FONT_A);
    builder.addTextLineSpace(100);
    builder.addText(`${order_id}\n`);
    builder.addTextLineSpace(10);
    builder.addText('Items being sent via post:\n');
    builder.addTextLineSpace(10);
    builder.addLine
    for(let i in cart_items){
        if(i === Object.keys(cart_items).length){
            builder.addTextLineSpace(200);
        }
        builder.addText(`${cart_items[i].product_name}\n`);
    }
    builder.addTextLineSpace(50);
    builder.addText('\n');
    builder.addBarcode(order_id, builder.BARCODE_GS1_128, builder.HRI_BELOW, builder.FONT_A, 4, 130);
    builder.addTextLineSpace(150);
    builder.addText('\n');
    builder.addBarcode(receipt_total, builder.BARCODE_CODE39, builder.HRI_BELOW, builder.FONT_A, 4, 130);
    builder.addTextLineSpace(50);
    builder.addText("\n");
    builder.addText(`Thanks for shopping with ${store_name}!\n`);
    builder.addText('-----------------------------------------------\n');
    builder.addCut(builder.CUT_FEED);
    return builder;
}

function getNewMagentoCartID(store_view_code="default") {
    return () => {
        return MageQL.mutate({
            mutation: CREATE_NEW_CART(),
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function getMagentoCart(cart_id, store_view_code="default") {
    return () => {
        return MageQL.query({
            query: GET_CART(cart_id),
            fetchPolicy: "no-cache",
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function getProductAttributesUID(sku, store_view_code="default") {
    return () => {
        return MageQL.query({
            query: GET_PRODUCT(sku),
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function addToMagentoCart(cart_id, item, UID, quantity=1, store_view_code="default") {
    return () => {
        return MageQL.mutate({
            mutation: ADD_TO_CART(cart_id, item.sku, UID, quantity),
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function removeFromMagentoCart(cart_id, item, store_view_code="default") {
    return () => {
        return MageQL.mutate({
            mutation: REMOVE_FROM_CART(cart_id, item.uid),
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function setMagentoAddress(cart_id, customer_details, shipping_address, store_view_code="default") {
    return () => {
        return MageQL.mutate({
            mutation: SET_ADDRESS(cart_id, customer_details, shipping_address),
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function setMagentoShippingMethod(cart_id, carrier_code, method_code, store_view_code="default") {
    return () => {
        return MageQL.mutate({
            mutation: SET_SHIPPING_METHOD(cart_id, carrier_code, method_code),
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function setMagentoPaymentMethod(cart_id, store_view_code="default") {
    return () => {
        return MageQL.mutate({
            mutation: SET_PAYMENT_METHOD(cart_id),
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function placeMagentoOrder(cart_id, store_view_code="default") {
    return () => {
        return MageQL.mutate({
            mutation: PLACE_ORDER(cart_id),
            context: {
                headers: {
                    Store: store_view_code
                }
            }
        })
    }
}

function placeAppSyncOrder(state, order_id) {
    return () => {
        return AppSyncQL.mutate({
            mutation: NEW_STD_ORDER(
                state.checkoutState.order_details.customer.email,
                order_id,
                Object.keys(state.checkoutState.cart_items).map(simple_sku => (
                    `{
                        configurable: "${state.checkoutState.cart_items[simple_sku].sku}",
                        simple: "${simple_sku}",
                        qty: "${state.checkoutState.cart_items[simple_sku].qty}",
                    }`
                )),
                {
                    street: state.checkoutState.order_details.shipping.street,
                    city: state.checkoutState.order_details.shipping.city,
                    region: state.checkoutState.order_details.shipping.region,
                    postcode: state.checkoutState.order_details.shipping.postcode
                },
                state.authState.store,
                state.checkoutState.shipping_method.carrier_code,
                state.checkoutState.fulfiller,
                state.checkoutState.total
            )
        })
    }
}
