import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class LoginRedirect extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <p>
                Authenticating user. If you can see this message, please refresh the page.
                If the problem persists, please contact the dev support.
            </p>
        );
    }
}

export default withRouter(connect()(LoginRedirect));
