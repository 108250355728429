import React, {Component} from "react";
import {connect} from "react-redux";
import {Row,Col} from "react-bootstrap";
import './reportshipping.css'
import {getStoresByUser} from "../../../actions/StoreActions";
import moment from "moment";
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Grid, Menu, Segment } from 'semantic-ui-react'

import StoreView from './storeView'
import RejectedView from './RejectedView';
import ManifestView from './ManifestView';
const mapStateToProps = state => {
    return {userId:state.authState.username,role:state.authState.role,permissions:state.authState.userPermissions}

};

const mapDispatchToProps = dispatch =>{
    return {

    }
};
class ReportingShipping extends Component {
    constructor(props) {
        super(props);
        this.state={
            stores: [],
            dates: [],
            selectedStore:null,
            selectedDate:null,
            store:null,
            activeItem: 'storeView',
            dateRange: {
                startDate:moment().startOf('month').format('YYYY-MM-DD:HH:mm:ss') ,
                endDate:  moment().format('YYYY-MM-DD:HH:mm:ss')
            },
            displayDate: {
                displayStart:moment().startOf('month').format('Do MMM') ,
                displayEnd:moment().format('Do MMM')
            },
            states: [
                {label:"QLD",value:"QLD"}
            ]
        }
    };
    async componentDidMount() {
        let stores = await getStoresByUser();
        this.setState({stores})
    }

    handleItemClick =(id) =>{
        this.setState({activeItem:id});
        console.log(id)
    };

    render(){
        return(
            <div className={"shipping-report"}>
                <Row>
                    <Col sm={3}>
                        <h1 className={"heading-text"}>Order Reporting</h1>
                    </Col>
                </Row>

                <Menu className="side-menu admin" fluid horizontal tabular>
                    <Menu.Item name='Stores Overview'
                               id={'storeView'}
                               active={this.state.activeItem === 'storeView'}
                               onClick={e=>{this.handleItemClick(e.target.id)}}/>
                    <Menu.Item name='Rejected Items'
                               id={'stockView'}
                               active={this.state.activeItem === 'stockView'}
                               onClick={e => {this.handleItemClick(e.target.id)}}/>
                    {
                        this.props.permissions.includes("reporting::full") ?
                            <Menu.Item name='Manifests'
                                       id={'manifestView'}
                                       active={this.state.activeItem === 'manifestView'}
                                       onClick={e => {this.handleItemClick(e.target.id)}}/>
                            :null
                    }
                </Menu>
                <div className="main">
                    {(() => {
                            switch (this.state.activeItem) {
                                case 'storeView':
                                    return <StoreView />;
                                case 'stockView':
                                    return <RejectedView />;
                                case 'manifestView':
                                    return <ManifestView />;
                                default :
                                    return null
                            }

                    })()}
                </div>
            </div>
        )
    }
}

const shippingReport = connect(mapStateToProps, mapDispatchToProps)(ReportingShipping);
export default shippingReport;
