import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import methods from "../../../../constants/shipping/MethodTypes"
import {updateShippingMethod, updateStage} from "../../../../actions/checkout/CheckoutActions";
import Modal from "react-bootstrap/Modal";
import LoaderButton from "../../../../components/LoaderButton";

const mapStateToProps = state => {
    return {
        order_total:state.checkoutState.total,
        available_methods: state.checkoutState.available_methods,
        shipping_method:state.checkoutState.shipping_method,
        stage:state.checkoutState.stage
    };
};

const mapDispatchToProps = dispatch =>{
    return {
        updateStage: (stage) =>{
            dispatch(updateStage(stage))
        },
        updateShippingMethod: (shipping_method) =>{
            dispatch(updateShippingMethod(shipping_method))
        }
    }
};

class CustomerDetailsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_method: null,
            store_selected: false,
            open_store_selector: false,
            standard_shipping: 0,
            express_shipping: 0,
        }
    };

    handleDeliveryChange = (method) => {
        this.setState({selected_method:method})
        this.props.updateShippingMethod(method);
    }

    handleBack = () => {
        this.props.updateStage(1);
    }

    submitDeliveryDetails = (event) =>{
        console.log(this.props)
        event.preventDefault();
        this.props.updateStage(3);
    }

    openStoreSelection = () => {
        this.setState({open_store_selector: true})
    }

    submitSelectedStore = () => {
    }
    /**
     * For CC post launch
     * @return {JSX.Element}
     */
    renderStoreSelector = () => {
        return(
            <Modal className = {"confirm-details-view"} animation={false} show={this.state.showModal} onHide={close}>
                <Modal.Header closeButton>
                    <Modal.Title><span>SELECT A STORE</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>

                    </Row>
                    <Row>

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.submitSelectedStore}>
                        Select Store
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render(){
        let selected = this.props.shipping_method;
        return(
            <Form className = "delivery-details-view" onSubmit = {this.submitDeliveryDetails}>
                {
                    this.state.open_store_selector ? this.renderStoreSelector() : null
                }
                <Row>
                    {
                        this.props.available_methods.map((method,index) => (
                            !['click_collect', 'sameday'].includes(method.carrier_code) ?
                                <Col key = {index} sm={6} style={{marginBottom: "8px"}}>
                                    <LoaderButton
                                        disabled={!method.available}
                                        onClick={()=>this.handleDeliveryChange(method)}
                                        className="btn-block"
                                        variant={selected?.carrier_code === method.carrier_code ?"success" : "outline-dark"}
                                        text={<span><span className="price">{method.amount.value === 0 ? "FREE" :  "$" + method.amount.value} </span>{method.carrier_title}</span>}
                                    />
                                </Col>
                            : null
                        ))
                    }
                </Row>
                {/* Back and Next */}
                <Row style={{paddingTop:'1rem'}}>
                    <Col lg={{span:3, offset:1}} xl={{span:4, offset:0}}>
                        <LoaderButton
                            className={"btn-block"}
                            variant="outline-dark"
                            onClick={this.handleBack}
                            text={"BACK"}
                        />

                    </Col>
                    {
                        (this.state.selected_method && !this.state.store_selected) === methods.CLICK_COLLECT ?
                            <Col lg={{span:3, offset:3}} xl={{span:2, offset:5}}>
                                <Button variant="primary" onClick = {this.openStoreSelection}>
                                    SELECT STORE
                                </Button>
                            </Col>
                            :<Col lg={{span:3, offset:3}} xl={{span:2, offset:5}}>
                                <LoaderButton
                                    disabled={this.props.shipping_method === null}
                                    className={"btn-block"}
                                    variant="success"
                                    type="submit"
                                    text={"NEXT"}
                                />
                            </Col>
                    }

                </Row>
            </Form>
        )
    }
}
const customerDetailsView = connect(mapStateToProps,mapDispatchToProps)(CustomerDetailsView);
export default withRouter(customerDetailsView);