// TODO: Build this from secrets manager.
// TODO: Add new cognito details
const newProd = {
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://b7x5erhef1.execute-api.ap-southeast-2.amazonaws.com/production/",
        NEW_URL: "https://oicdjabbm9.execute-api.ap-southeast-2.amazonaws.com/production/",
    },
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "universal-s3-production-attachmentsbucket-1jjcvn7f7vtyu"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_lclX7u9j6",
        APP_CLIENT_ID: "4moto9gr6ho6i1n386m6g4e1fu",
        IDENTITY_POOL_ID: "ap-southeast-2:1aa3b103-2575-4121-a590-ccae35c3f191",
    },
    magento:{
        baseUrl: "https://fulfil.universalstore.com/"
    },
    IotData: {
        ENDPOINT: "a3bj1niy7bd2dv-ats.iot.ap-southeast-2.amazonaws.com",
    },
    mastersoft: {
        user:'TPPROD',
        key:'uVr5HsL0RTnhqO78T0iawr0HDsj8btOV'
    },
    appSync: {
        REGION: "ap-southeast-2",
        URL: "https://aeyzhir5r5gclkfhyqi4rin6uq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
        API_KEY: "da2-enyeutah7veenetpfuvom33lve"
    }
};
const local = {
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://9f3hs7jz5i.execute-api.ap-southeast-2.amazonaws.com/dev/",
        NEW_URL: "https://nd0fxcg4o3.execute-api.ap-southeast-2.amazonaws.com/staging/",
        USERS_URL: "https://533qujo5p2.execute-api.ap-southeast-2.amazonaws.com/staging/",
    },
    s3: {
        REGION: "ap-southeast-2",
        BUCKET: "universal-s3-dev-attachmentsbucket-e9yamdv45ca1"
    },
    //cognito: {
    //    REGION: "ap-southeast-2",
    //    USER_POOL_ID: "ap-southeast-2_57M9bggIH",
    //    APP_CLIENT_ID: "5nfvudg1vtfuigp387l94rl7b5",
    //    IDENTITY_POOL_ID: "ap-southeast-2:75c2ce10-80ba-437c-8ab9-923a265b6977"
    //},
    cognito: {
        PROVIDER: "AzureAD-staging",
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_VXw4J50o9",
        APP_CLIENT_ID: "4ne1l3op5ro95njfjtf12ot13o",
        IDENTITY_POOL_ID: "ap-southeast-2:7644830d-e245-4e97-9a51-b4c4979e7f59",
    	OAUTH: {
    	    DOMAIN: "staging-user-domain.auth.ap-southeast-2.amazoncognito.com",
    		SCOPE: [
                'email',
                'openid',
                'aws.cognito.signin.user.admin',
                'profile'
            ],
            redirectSignIn: 'https://local-team.universalstore.com:3000/redirect',
            redirectSignOut: 'https://local-team.universalstore.com:3000',
    	}
    },
    //cognito: {
    //	PROVIDER: "AzureAD-staging",
    //	REGION: "ap-southeast-2",
    //	USER_POOL_ID: "ap-southeast-2_VXw4J50o9",
    //	APP_CLIENT_ID: "4ne1l3op5ro95njfjtf12ot13o",
    //	IDENTITY_POOL_ID: "ap-southeast-2:87909fef-1ac4-43d6-a044-3f523e2078eb",
    //	OAUTH: {
    //		DOMAIN: "staging-user-domain.auth.ap-southeast-2.amazoncognito.com",
    //		SCOPE: [
    //            'email',
    //            'openid',
    //            'aws.cognito.signin.user.admin',
    //            'profile'
    //        ],
    //        redirectSignIn: 'https://dev-team.universalstore.com',
    //        redirectSignOut: 'https://dev-team.universalstore.com',
    //	}
    //},
    magento:{
        baseUrl: "https://uni.magento.cloud"
    },
    IotData: {
        ENDPOINT: "a3r0blcjy3rkxz-ats.iot.ap-southeast-2.amazonaws.com",
    },
    mastersoft: {
        user:'TPINT',
        key:'0juPKuRzzbf7L8NzZx7BmnD3pFILFUBs'
    },
    appSync: {
        REGION: "ap-southeast-2",
        URL: "https://rkix3qacpnexbkvdrrp7dfbxam.appsync-api.ap-southeast-2.amazonaws.com/graphql",
        API_KEY: "da2-pwqkntes6fczbi4gat4aqcal7a"
    }
}
// TODO: Add new cognito details
const integration ={
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://75fbbwryo8.execute-api.ap-southeast-2.amazonaws.com/indev/"
    },
    s3: {
        REGION: "ap-southeast-2", BUCKET: "universal-s3-indev-attachmentsbucket-admry4yo01px"
    },
    cognito: {
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_vMqw70xzA",
        APP_CLIENT_ID: "6pdpn8ehmmavp767uap471s3k",
        IDENTITY_POOL_ID: "ap-southeast-2:8d6507c7-e847-4c8b-b749-d70cc2adf92a",
    },
    magento:{
        baseUrl: "https://integration-5ojmyuq-htd55emnl2j4g.ap-3.magentosite.cloud/"
    },
    IotData: {
        ENDPOINT: "a3r0blcjy3rkxz-ats.iot.ap-southeast-2.amazonaws.com",
    },
    mastersoft: {
        user:'TPINT',
        key:'0juPKuRzzbf7L8NzZx7BmnD3pFILFUBs'
    },
    appSync: {
        REGION: "ap-southeast-2",
        URL: "https://rkix3qacpnexbkvdrrp7dfbxam.appsync-api.ap-southeast-2.amazonaws.com/graphql",
        API_KEY: "da2-pwqkntes6fczbi4gat4aqcal7a"
    }
};
// TODO: Add new cognito details
const dev= {
    apiGateway: {
        REGION: "ap-southeast-2",
        URL: "https://9f3hs7jz5i.execute-api.ap-southeast-2.amazonaws.com/dev/",
        NEW_URL: "https://nd0fxcg4o3.execute-api.ap-southeast-2.amazonaws.com/staging/",
        USERS_URL: "https://533qujo5p2.execute-api.ap-southeast-2.amazonaws.com/staging/",
    },
    s3: {
        REGION: "ap-southeast-2", BUCKET: "universal-s3-dev-attachmentsbucket-e9yamdv45ca1"
    },
    // cognito: {
    //     REGION: "ap-southeast-2",
    //     USER_POOL_ID: "ap-southeast-2_57M9bggIH",
    //     APP_CLIENT_ID: "5nfvudg1vtfuigp387l94rl7b5",
    //     IDENTITY_POOL_ID: "ap-southeast-2:75c2ce10-80ba-437c-8ab9-923a265b6977"
    // },
    cognito: {
        PROVIDER: "AzureAD-staging",
        REGION: "ap-southeast-2",
        USER_POOL_ID: "ap-southeast-2_VXw4J50o9",
        APP_CLIENT_ID: "4ne1l3op5ro95njfjtf12ot13o",
        IDENTITY_POOL_ID: "ap-southeast-2:7644830d-e245-4e97-9a51-b4c4979e7f59",
        OAUTH: {
    	    DOMAIN: "staging-user-domain.auth.ap-southeast-2.amazoncognito.com",
    	    SCOPE: [
                   'email',
                   'openid',
                   'aws.cognito.signin.user.admin',
                   'profile'
               ],
               redirectSignIn: 'https://dev-team.universalstore.com/redirect',
               redirectSignOut: 'https://dev-team.universalstore.com',
        }
    },
    magento:{
        baseUrl: "https://fulfil-staging.universalstore.com/"
    },
    IotData: {
        ENDPOINT: "a3bj1niy7bd2dv-ats.iot.ap-southeast-2.amazonaws.com",
    },
    mastersoft: {
        user:'TPDEV',
        key:'D3VBbRPcXCwzqAAL2beQnFHNRUZXC5ql'
    },
    appSync: {
        REGION: "ap-southeast-2",
        URL: "https://hapbq265lnfbdaltzxabdy4j4y.appsync-api.ap-southeast-2.amazonaws.com/graphql",
        API_KEY: "da2-2se7ziirtrgvnjfmie54pgw2qa"
    }
}

let config;
if(process.env.REACT_APP_STAGE === 'production'){
    config = newProd;
}else if(process.env.REACT_APP_STAGE === 'staging'){
    config = dev;
}else if (process.env.REACT_APP_STAGE === 'integration'){
    config = integration;
} else {
    config = local;
}
export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
