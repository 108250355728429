import React, {Component} from "react";
import {connect} from "react-redux";
import {Row,Col} from "react-bootstrap";
import './reportshipping.css'
import Select from "../../../components/form/inputs/Select";
import {getAllStores, getStoresByUser} from "../../../actions/StoreActions";
import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import StoreOrders from "./StoreOrders";
import Input from "../../../components/form/inputs/Input";
import ToolTip from "../../../components/form/inputs/ToolTip";
import Filters from "./Filters";
import {generateCompleteArchivedShipments, generateOlderShipments} from "../../../actions/ReportingActions";

const mapStateToProps = state => {
    return {userId:state.authState.username, role:state.authState.role, permissions: state.authState.userPermissions}

};

const mapDispatchToProps = dispatch =>{
    return {
        generateCompleteArchivedShipments : (store,date) => {dispatch(generateCompleteArchivedShipments(store,date))},
        generateOlderShipments : (storeId)=>{dispatch(generateOlderShipments(storeId))},
        getStoresByUser : () => {return dispatch(getStoresByUser())},
        getAllStores: () => {return dispatch(getAllStores())}
    }
};
class StoreView extends Component {
    constructor(props) {
        super(props);
        this.state={
            dateRange: {},
            store:null,
            state:null,
            method: null,
            stores: [],
            allStores: [],
            myStoresOptions: [],
        }
    };

    async componentDidMount() {
        // TODO: Get a list of all the stores. We need to pass this up to the reporting service.
        // TODO: Store the list of all stores in the state. Keep it seperate from the stores options.
        let allStores = await this.props.getAllStores()
        let myStores = this.props.getStoresByUser();
        let myStoresOptions = myStores.map(store => {
            return {label:store,value:store} //TODO: get the store name
        });
        myStoresOptions.unshift({label:"All",value:null});
        this.setState({stores: myStoresOptions, allStores})
    }
    handleMethod =(method) => {
        this.setState({method});
    };
    handleState = (state) => {
        this.setState({state})
    };
    handleStore = (store) => {
        this.setState({store})
    };
    handleDate = (dateRange) => {
       this.setState({dateRange})
    };

    generateFulfilledShipments = () => {
        this.props.generateCompleteArchivedShipments(this.state.store,this.state.dateRange)
    };

    generateOlderShipments = () => {
        this.props.generateOlderShipments(this.state.store)
    };

    render(){
        return(
            <div>
                <Row className={"shipping-select-wrapper"}>
                    <Col sm={11}>
                        <Filters handleMethod = {this.handleMethod}
                                 handleState = {this.handleState}
                                 handleStore = {this.handleStore}
                                 handleDate = {this.handleDate}
                                 role = {this.props.role}
                                 stores = {this.state.stores}
                                 allStores = {this.state.allStores}
                                 permissions = {this.props.permissions}/>
                    </Col>
                    <Col sm={1}>
                        {(this.state.store && this.state.dateRange) ?
                            <button onClick={ () => this.generateFulfilledShipments()}>Generate Fulfilled</button>
                            :null
                        }
                    </Col>
                    <Col sm={1}>
                        {(this.state.store && this.state.dateRange) ?
                            <button onClick={ () => this.generateOlderShipments()}>Generate Old</button>
                            :null
                        }
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <StoreOrders state = {this.state.state}
                                     method = {this.state.method}
                                     store={this.state.store}
                                     status = {this.state.status}
                                     dateRange={this.state.dateRange}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const storeOverview = connect(mapStateToProps, mapDispatchToProps)(StoreView);
export default storeOverview;
