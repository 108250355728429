import { createStore, applyMiddleware, combineReducers} from "redux";
import {connectRouter, routerMiddleware} from 'connected-react-router'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import Amplify, {Auth} from "aws-amplify";
import config from "../config";
import set from 'lodash/set';
import has from 'lodash/has';
import { createBrowserHistory } from 'history'
import authReducer from "../reducers/AuthReducer";
import NotificationReducer from "../reducers/NotificationsReducer";
import * as type from "../constants/actions/ActionTypes";
/*
 * Initializing AWS Amplify with our AWS Configurations for Frontend
 */

Amplify.configure({
    Auth: {
	    mandatorySignIn: true,
        region: config.cognito.REGION,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        oauth: {
            domain: config.cognito.OAUTH.DOMAIN,
            scope: config.cognito.OAUTH.SCOPE,
            redirectSignIn: config.cognito.OAUTH.redirectSignIn,
            redirectSignOut: config.cognito.OAUTH.redirectSignOut,
            responseType: 'token'
        }
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    }, 
    API: {
        endpoints: [
            {
                name: "users",
                endpoint: config.apiGateway.URL, region: config.apiGateway.REGION,
                custom_header: async () => {
                    return { 'x-access-token': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                },
            },
            {
                name: "new_users",
                endpoint: config.apiGateway.USERS_URL, 
                region: config.apiGateway.REGION,
                custom_header: async () => {
                    return { 'x-access-token': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                },
            },
            {
                name: "stores",
                endpoint: config.apiGateway.URL + "stores/",
                custom_header: async () => {
                    return { 'x-access-token': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                },
                region: config.apiGateway.REGION
            },
            {
                name: "catalog",
                endpoint: config.apiGateway.NEW_URL + "catalog/",
                region: config.apiGateway.REGION
            },
            {
                name: "magento",
                endpoint: config.apiGateway.URL, region: config.apiGateway.REGION
            },
            {
                name: "shipping",
                endpoint: config.apiGateway.URL,
                custom_header: async () => {
                    return { 'x-access-token': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                },
                region: config.apiGateway.REGION
            },
            {
                name: "shipment",
                endpoint: config.apiGateway.URL + "shipment/",
                custom_header: async () => {
                    return { 'x-access-token': `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
                },
                region: config.apiGateway.REGION
            }
        ]
    }
});


export const history = createBrowserHistory();

/*
reducers to add initially
 */
export const reducers ={
    authState: authReducer,
    notificationState : NotificationReducer,
    router: connectRouter(history)
};
/*
 using a custom combine reducers method which allows us to inject reducers dynamically.
  */
const  initialReducers = combineReducers({
    authState: authReducer,
    notificationState : NotificationReducer,
    router: connectRouter(history)
});
/*creating a RootReducer to allow a full RESET of the state when logging out */
// const rootReducer = (state, action) => {
//     console.log(action)
//     if(action.payload)
//     {
//         if(action.payload.location){
//             console.log(action.payload.location.pathname);
//             if(action.payload.location.pathname === '/login'){
//                 console.log('supposed to make state undefined here')
//                 state = undefined
//             }
//         }
//     }
//
//     return initialReducers(state, action);
// };

export const makeRootReducer =
    (asyncReducers) =>
        (state, action) =>
            combineReducers({
                // reducers...
                ...asyncReducers
            })(action.type === 'LOGOUT_USER' ? undefined : state, action)

/*
 store creation with necessary middleware
  */
//const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 }); // if tracing of action is required us this instead of composeWithDevTools
const myStore = createStore(initialReducers, composeWithDevTools(
    applyMiddleware(
        routerMiddleware(history),
        thunk // extra enhancers
)));
myStore.injectedReducers = reducers;
/*
Injects reducers/ states dynamically as needed
 */
export function injectReducer(key, reducer, force = false, store = myStore) {
    // If already set, do nothing.
    if (!has(store.injectedReducers, key) || force) {
        set(store.injectedReducers, key, reducer);
        store.replaceReducer(makeRootReducer(store.injectedReducers));
    }
}
/*
*Top level Dispatch function to logout user, ensuring all states are cleared and reset when logged out.
 */
export const logoutUser = ()=>{
    myStore.dispatch({type:type.LOGOUT_USER});
    localStorage.clear(); // clear all storage regarding cognito
};
export default myStore;
