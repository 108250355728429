import * as lambda from "../actions/ShippingCalls"
import * as lambdaCalls from "../actions/LambdaCalls"
import * as type from "../constants/actions/ShippingActionTypes";
import * as errorTypes from "../constants/actions/ShippingErrorTypes";
import * as store from "../actions/StoreActions";
import * as cogLib from "../libs/cognitoLib"
import config from "../config";
import * as s3 from "../libs/awsLib";
import * as notification from '../actions/NotificationActions'
import * as jsPDF from 'jspdf';

//Shipment Constants
import METHOD from "../constants/shipping/MethodTypes";
import STATUS from "../constants/shipping/StatusTypes";
import { getProductsBySkus } from './catalog/CatalogCalls'

var moment = require('moment-timezone');

const loading = (loading) => {
    return (
        {
            type:type.LOADING_SHIPMENTS,
            loading:loading
        }
    )
};

export const loggedIn = (login) => {
    return (
        {
            type:type.LOG_IN,
            payload:login
        }
    )
};

const addShipment =  (shipments) => {
    return (
        {
            type:type.ADD_SHIPMENTS,
            payload:shipments
        }
    )
};

const clearShipments = ()=> {
    return (
        {
            type:type.CLEAR_SHIPMENTS
        }
    )
};

const setFocusedShipment = (shipment) =>{
    return (
        {
            type:type.SET_FOCUSED_SHIPMENT,
            payload:shipment
        }
    )
};

const setAuspostInfo = (auspost) =>{
    return (
        {
            type:type.SET_AUSPOST_DETAILS,
            payload:auspost
        }
    )
};

const setLabels = (shipmentId,labels) => {
    return (
        {
            type:type.SET_AUSPOST_LABEL,
            shipmentId: shipmentId,
            payload:labels
        }
    )
}

const setIbt = (shipmentId,ibt) => {
    return (
        {
            type:type.SET_REJECTED_IBT,
            shipmentId: shipmentId,
            payload:ibt
        }
    )
}

const setManifest = (manifest) => {
    return (
        {
            type:type.SET_MANIFEST,
            payload:manifest
        }
    )
}

const addPendingOverview = (data) => {
    return (
        {
            type:type.PENDING_OVERVIEW,
            payload:data
        }
    )
}

const updateShipmentInView = (shipment) => {
    return (
        {
            type:type.UPDATE_SHIPMENT,
            shipment: shipment
        }
    )
}

const addNewShipmentToView = (shipment) => {
    return (
        {
            type:type.ADD_NEW_SHIPMENT,
            shipment: shipment
        }
    )
}

const removeShipmentFromView = (shipment) => {
    return (
        {
            type:type.REMOVE_SHIPMENT,
            shipment: shipment
        }
    )
}

/**
 * Using sfsId stored by intranet to change item availability, as sku could be the same
 */
export function updateItemAvailability (shipmentId,localId,availability,reason) {
    return (
        {
            type:type.UPDATE_ITEM_AVAILABILITY,
            shipmentId:shipmentId,
            localId:localId,
            avail:availability,
            reason: reason ? reason : null
        }
    )
}

export function updateShipmentStatus(shipmentId,status){
    return (
        {
            type:type.UPDATE_SHIPMENT_STATUS,
            shipmentId:shipmentId,
            status:status,
        }
    )
}
export function updateShipmentStage(stage,shipmentId){
    return (
        {
            type:type.UPDATE_SHIPMENT_STAGE,
            payload:stage,
            shipmentId:shipmentId
        }
    )
}

export function updateFulfiller(fulfiller){
    return (
        {
            type:type.UPDATE_FULFILLER,
            payload:fulfiller
        }
    )
}

export function updateShipmentsWithFulfiller(fulfiller){
    return (
        {
            type:type.UPDATE_SHIPMENTS_WITH_FULFILLER,
            payload:fulfiller
        }
    )
}

export function updateProductImages(images) {
    return (
        {
            type:type.UPDATE_ITEM_IMAGES,
            images,
        }
    )
}

/*Fetch and Logic functions*/
 const fetchResponsibleShipments = (store) => {
  return () => {
      return lambda.getResponsibleShipments(store).then(response => {
          return response;
      })
    }
};

 const fetchSingleReponsibleShipment = (shipmentId)=>{
    return () => {
        return lambda.getSingleResponsibleShipment(shipmentId).then(response => {
            return response;
        })
    }
}

const fetchLabel = (shipmentId,parcelArray,createdAt) => {
    return() => {
        return lambda.getLabel(shipmentId,parcelArray,createdAt).then(response => {
            return response;
        }).catch({

        })
    }
};

const updateStoreManifestDate = (store) => {
    return() => {
        return lambdaCalls.updateStore(store).then(response => {
            return response;
        })
    }
};

const fetchManifest = (storeId) => {
    return() => {
        return lambda.getManifest(storeId).then(response => {
            return response;
        })
    }
};

const fetchPickslip = (shipmentId) => {
    return() => {
        return lambda.getPickslip(shipmentId).then(response => {
            return response;
        })
    }
};

const fetchCCLabel = (shipment,parcels,store) => {
    return() => {
        return lambda.generateCCLabel(shipment,parcels,store).then(response=>{
            return response;
        })
    }
};

const fetchAllPickslips = (store, type, web_store) => {
    return() => {
        return lambda.getAllPickslips(store, type, web_store).then(response => {
            return response;
        })
    }
};

const generateManifest = (store) => {
    return() => {
        return lambda.generateManifest(store).then(response => {
            return response;
        })
    }
}

const fulfillShipment = (storeId, shipmentToEdit, status, collection=false) =>{
    return() => {
        return lambda.fulfillShipment(storeId, shipmentToEdit, status, collection).then(response => {
            return response;
        })
    }
};

const fulfilShipment = (shipment,storeId,status) =>{
    return() => {
        return lambda.fulfilShipment(shipment,storeId,status).then(response => {
            return response;
        })
    }
};

const rejectShipment = (shipment,storeId,status) =>{
    return() => {
        return lambda.rejectShipment(shipment,storeId,status).then(response => {
            return response;
        })
    }
};


const getFulfilledByIncrement = (incrementId,store) => {
    return () => {
        return lambda.queryFulfilledByIncrement(incrementId,store).then(response => {
            return response;
        })
    }
};

const fetchPendingDetails = (store,userId=null) => {
    return () => {
        return lambda.getPendingShipments(store,null,userId).then(response => {
            return response;
        })
    }
};

const fetchFulfillerData = (store,futuraId) => {
    return () => {
        return lambda.getFulfillerData(store,futuraId).then(response => {
            return response;
        })
    }
};

/**
 *
 * @returns {function(*, *): *}
 */
export function getShipments () {
    return (dispatch,getState) => {
        dispatch(loading(true));
        dispatch(clearShipments());
        let jwt = getState().authState.JWT;
        let store = getState().authState.store;
        return dispatch(fetchResponsibleShipments(store)).then(response => {
            if(response.error) {
                dispatch(notification.refreshAction());
                dispatch(loading(false));
                return;
            }

            // Grab a list of all the images we need from the catalog api
            dispatch(getProductImages(response.shipments.map(shipment => shipment.items.map(item => item.group_number)).flat()));

            //sort shipments before they are displayed.
            let shipments = response.shipments.sort(function(a, b) {

                //Determine if comparing High Priority Shipments.
                if (isPriorityShipment(a,b)) {
                    return comparePriorityShipment(a, b);
                }

                //Compare Express / Standard / CC (Pending Pickup)
                return compareShipment(a,b);
            });

            //If a shipment has its label printed and isn't complete don't allow stores to manifest
            if (shipments.some((shipment) => shipment.labelsPrinted)) {
                dispatch(setManifest('disabled'));
            }
            dispatch(addShipment(shipments));
            dispatch(updateShipmentsWithFulfiller(getState().shippingState.fulfiller.futuraId));
            dispatch(loading(false));
        }).catch(() => {
            dispatch(notification.refreshAction());
        })
    }
}

export function getProductImages(skus) {
    return (dispatch, getState) => {
        let store_view_code = getState().authState.store_view_code;
        return getProductsBySkus(skus, store_view_code).then(response => {
            dispatch(updateProductImages(response.images));
        })
    }
}

export function getCCShipments (){
    return (dispatch,getState) => {
        dispatch(loading(true));
        dispatch(clearShipments());
        let jwt = getState().authState.JWT;
        let store = getState().authState.store;
        return dispatch(fetchResponsibleShipments(store)).then(response => {
            if (response.error) {
                console.log('error retrieving responsible shipments');
                dispatch(loading(false));
            } else {
                let shipments = response.shipments.filter(obj => obj.status === "PENDING_PICKUP");
                dispatch(addShipment(shipments));
                dispatch(updateShipmentsWithFulfiller(getState().shippingState.fulfiller.futuraId));
                dispatch(loading(false));
            }
        })
    }
}

export function searchShipments(text,archived = false){
    return (dispatch,getState) => {
        dispatch(loading(true));
        let allShipments = getState().shippingState.allShipments;
        let store = getState().authState.store;
        if(archived){
            if(text.length > 0){
                return dispatch(getFulfilledByIncrement(text,store)).then(response=>{
                    if(response.status){
                        console.log(response);
                        if(response.shipments.length > 0){
                            dispatch(addShipment(response.shipments));
                            dispatch(loading(false));
                        }else{
                            dispatch(getShipments());
                        }
                    }else {
                        console.log('error')
                    }
                });
            }else {
                dispatch(getShipments());
            }

        }else{
            if(text.length > 0){
                let newArray = allShipments.filter(x => x.incrementId.includes(text));
                dispatch(addShipment(newArray));
                dispatch(loading(false));
            }else {
                dispatch(getShipments());
            }
        }
    };

}

export function reloadShipments(){
    return (dispatch,getState) => {
        dispatch(clearShipments());
        dispatch(loading(true));
        let jwt = getState().authState.JWT;
        let store = getState().authState.store;
        return dispatch(fetchResponsibleShipments(store)).then(response => {
            if(response.error){
                console.log('error retrieving responsible shipments')
            }else {
                dispatch(addShipment(response.shipments));
                dispatch(updateShipmentsWithFulfiller(getState().shippingState.fulfiller.futuraId));
                dispatch(loading(false));
            }
        })
    }
}

/**
 * Function that notifies frontend user when there is any updates with a shipment triggered by the API
 * @param shipmentId
 * @param type
 * @returns {function(*, *): (*|undefined)}
 */
export function updateShipmentLive(shipmentId,type){
    return (dispatch,getState) => {
        //let loggedIn = getState().shippingState.loggedIn
        return dispatch(fetchSingleReponsibleShipment(shipmentId)).then(response => {
            if(response.status){
                let shipment = response.shipment
                dispatch(updateShipmentInView(shipment));
                //dispatch(notification.shipmentNotification(createShipmentMessage(type,shipment)));
            }
        })
        if(loggedIn){
        }else {
            //dispatch(notification.shipmentNotification('There was an update with a shipment'));
        }
    }
}

/**
 * Function that notifies frontend user when there is a new shipment for their store triggered by the API
 * @param shipmentId
 * @returns {function(*, *): *}
 */
export function addShipmentLive(shipmentId){
    return (dispatch,getState) => {
        let loggedIn = getState().shippingState.loggedIn
        if(loggedIn){
            return dispatch(fetchSingleReponsibleShipment(shipmentId)).then(response => {
                let shipment = response.shipment
                dispatch(addNewShipmentToView(shipment));
                //dispatch(notification.shipmentNotification(createShipmentMessage("ADD_SHIPMENT",shipment)));
            })
        }else {
            //dispatch(notification.shipmentNotification('You have a new shipment to fulfill'));
        }
    }
}

export function getFocusedShipment(shipmentId) {
    return (dispatch,getState) =>{
        let focused = getState().shippingState.focusedShipment;
        if(focused === shipmentId){
            dispatch(setFocusedShipment(null));
        }else{
            dispatch(setFocusedShipment(shipmentId));
        }
    }
}


/**
 * Updating shipments in the backend for C&C / SFS
 * @param shipmentId - shipment to update
 * @param status - what status we are updating the backend with
 * @param reason - if was rejected, a reason is provided
 * @param collection - if collection si true, shipment will be treated as a click and collect order and follows a different flow
 * @returns {Function}
 */
export function updateShipment(shipmentId,status,reason=false,collection=false){
    return (dispatch,getState) => {
        dispatch(loading(true));
        dispatch(updateShipmentStatus(shipmentId, status));
        let shipments = getState().shippingState.shipments;
        let storeId = getState().authState.store;
        let shipmentToEdit = shipments.find(x => x.shipmentId === shipmentId);
        if (!shipmentToEdit) {
            dispatch(notification.errorNotification('Shipment not found! Please try again'));
            dispatch(loading(false));
        } else {
            shipmentToEdit.label = "";   //removing label before sending across to backend
            shipmentToEdit.fulfiller = getState().shippingState.fulfiller.futuraId // add fulfiller to shipment
            if (collection) {
                if (status === "COMPLETE") {
                    dispatch(fulfilShipment(shipmentToEdit, storeId, status)).then((response) => {
                        if (response.status) {
                            dispatch(loading(false));
                            dispatch(notification.successNotification('Shipment successfully picked up.'));
                        }
                    }).catch((e) => {
                        console.log(e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                        dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    })
                } else if (status === "REJECTED") {
                    dispatch(rejectShipment(shipmentToEdit, storeId, status)).then((response) => {
                        if (response.status) {
                            dispatch(loading(false));
                        }
                    }).catch((e) => {
                        console.log(e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                        dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    })
                } else {
                    dispatch(fulfilShipment(shipmentToEdit, storeId, status)).then((response) => {
                        if ('status' in response) {
                            if ('rejection' in response) {
                                let pdf = createFaultyItemPdf(response.rejection);
                                let url = printPreview(pdf);
                                dispatch(setIbt(shipmentId, url))
                                dispatch(loading(false));
                            }
                            dispatch(loading(false));
                            dispatch(notification.successNotification('Shipment successfully packed & ready for pick-up.'));
                        } else {
                            dispatch(notification.successNotification('Shipment successfully packed & ready for pick-up.'));
                            dispatch(loading(false));
                        }
                    }).catch((e) => {
                        console.log(e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                        dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    })
                }
            } else {
                if (status === "COMPLETE") {
                    dispatch(fulfilShipment(shipmentToEdit, storeId, status)).then((response) => {
                        if (response.status) {
                            if ('rejection' in response) {
                                let pdf = createFaultyItemPdf(response.rejection);
                                let url = printPreview(pdf);
                                dispatch(setIbt(shipmentId, url))
                                dispatch(loading(false));
                            }
                        }
                        dispatch(loading(false));
                    }).catch((e) => {
                        console.log(e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                        dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    })
                } else if (status === "REJECTED") {
                    dispatch(rejectShipment(shipmentToEdit, storeId, status)).then((response) => {
                        if (response.status) {
                            if ('rejection' in response) {
                                let pdf = createFaultyItemPdf(response.rejection);
                                let url = printPreview(pdf);
                                dispatch(setIbt(shipmentId, url))
                                dispatch(loading(false));
                            }
                        }
                        dispatch(loading(false));
                    }).catch((e) => {
                        console.log(e);
                        dispatch(notification.errorNotification('Network error! Please try again'));
                        dispatch(loading(false));
                        dispatch(updateShipmentStage('STAGE_THREE', shipmentId));
                    })
                }

            }
        }
    }

}

/**
 * Calcuilating Australia post labels
 * @param shipmentId
 * @param parcels - parcel information for auspost label creation in magento {parcelType, parcelItems}
 * @param createdAt
 * @returns {Function}
 */
export function calculateLabels(shipmentId,parcels,createdAt){
    return (dispatch,getState) => {
        dispatch(loading(true));
        let parcelArray = [];
        for(let parcel of parcels){
            let parcelInfo ={parcelItems: [],parcelType:parcel.parcelType};
            for(let item of parcel.items){
                parcelInfo.parcelItems.push(item.sku);
            }
            parcelArray.push(parcelInfo);
        }
        dispatch(fetchLabel(shipmentId,parcelArray,createdAt)).then(response => {
            if(response.error){
                dispatch(notification.errorNotification(errorTypes[response.error] ? errorTypes[response.error]: "Error calculating Label! Please contact dev support"));
                dispatch(loading(false));
                dispatch(updateShipmentStage('STAGE_TWO',shipmentId));
            }else{
                let labels = response;
                let blob = b64toBlob(labels.label, 'application/pdf');
                s3.s3Upload(blob,'labels/'+shipmentId+'-label.pdf').then(()=>{
                    let url = printPreview(labels.label);
                    dispatch(setLabels(shipmentId,url));
                    dispatch(updateShipmentStage('STAGE_THREE',shipmentId));
                    dispatch(loading(false));
                });
            }
        }).catch((e)=>{
            console.log(e.message);
            dispatch(notification.errorNotification('Network error! Please try again'));
            dispatch(loading(false));
            dispatch(updateShipmentStage('STAGE_TWO',shipmentId));
        })
    }
}

export function getFulfillerData  (futuraId) {
    return(dispatch,getState) => {
        dispatch(loading(true));
        let storeId = getState().authState.store;

        dispatch(fetchFulfillerData(storeId, futuraId)).then(response => {
            if (!response.status) {
                dispatch(notification.errorNotification(response.error + '! Please try again'));
                dispatch(loading(false));
            } else {
                dispatch(updateFulfiller(response.fulfillerInfo));
                dispatch(store.fetchSingleStore(storeId)).then(response => {
                    console.log(response)
                    if (response.pickupInfo) {
                        dispatch(setAuspostInfo(response.pickupInfo));
                        dispatch(loading(false));
                        dispatch(loggedIn(true));
                    }
                }).catch((e) => {
                    checkSession();
                    console.log(e);
                    dispatch(loading(false));
                });
            }
        }).catch((e) => {
            dispatch(notification.errorNotification(response.error + '! Please try again'));
            checkSession();
            console.error(e);
            dispatch(loading(false));
        })
    }
}

export function getStorePendingCount(){
    return (dispatch,getState) => {
        let store = getState().authState.store;
        dispatch(fetchPendingDetails(store)).then(response=>{
            //console.log(response);
            dispatch(addPendingOverview(response.data));
        })
    }
}

/**
 * Get Manifest will check if there is a manifest for today
 * if not it will generate and spend 5 seconds awaiting a response from magento.
 * @returns {Function}
 */
export function getManifest(){
    return(dispatch,getState) => {
        let storeId = parseInt(getState().authState.store);
        dispatch(fetchManifest(storeId)).then(response=>{
            let manifest = response.manifest;
            let testDate=new Date(manifest.date);
            if(isToday(testDate)){
                dispatch(notification.successNotification('Manifest ready! Go ahead and download it'));
                dispatch(setManifest(manifest.location));
            }else {
                dispatch(notification.warningNotification('Generating Manifest please wait'));
                dispatch(generateManifest(storeId)).then(response => {
                    if(response.status){
                        if(response.manifest){
                            // Update the manifest date of the store.
                            dispatch(updateStoreManifestDate({ storeId: storeId, manifest: { location: response.manifest, date: new Date() } }));
                            dispatch(notification.successNotification('Manifest ready! Go ahead and download it'));
                            dispatch(setManifest(response.manifest));
                        }
                    }else {
                       dispatch(notification.errorNotification('Seems like that failed! Please try click Generate Manifest again'))
                    }
                })
            }
        })
    }
}

export function getPickslips(shipmentId){
    return (dispatch,getState) => {
        dispatch(loading(true));
        let shipments = getState().shippingState.shipments;
        let shipmentToEdit = shipments.find(x => x.shipmentId === shipmentId);
        dispatch(fetchPickslip(shipmentId)).then(response => {
            if(response.pickslip){
                let pdf = response.pickslip;
                const downloadLink = document.createElement('a');
                downloadLink.href = `data:application/pdf;base64,${pdf}`;
                downloadLink.download = 'pickSlip-'+shipmentToEdit.incrementId+'.pdf';
                downloadLink.click();
                dispatch(loading(false));
            }else {
                dispatch(notification.errorNotification('Failed to load pickslip'));
                dispatch(loading(false));
            }

        });


    }
}

export function getAllPickslips(store, type, web_store){
    if (type !== 'all') {
        return (dispatch,getState) => {
            dispatch(loading(true));
            dispatch(notification.warningNotification('Pickslips will open in a new tab when loaded'));
            let store = parseInt(getState().authState.store);
            dispatch(fetchAllPickslips(store, type, web_store)).then(response => {
                let randomQuery = Math.random();
                let pickslip = null;
                pickslip = response.pdf.replace('/app/htd55emnl2j4g/pub','');
                pickslip = response.pdf.replace('/app/htd55emnl2j4g_stg/pub','');
                const theWindow = window.open(config.magento.baseUrl +pickslip+'?'+randomQuery);
                const theDoc = theWindow.document;
                const theScript = document.createElement('script');
                function injectThis() {
                    window.location.reload();
                }
                theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
                theDoc.body.appendChild(theScript);
                dispatch(loading(false));
            });
        }


    } else {
        return (dispatch,getState) => {
            let store = getState().authState.store;
            let randomQuery = Math.random();
            const pickslip_name = (web_store === 'perfect_stranger' ? 'ps-' : '')  + `${store}-all-pickslips.pdf?`;
            const theWindow = window.open(config.magento.baseUrl +'media/pickslips/'+ pickslip_name + randomQuery);
            const theDoc = theWindow.document;
            const theScript = document.createElement('script');
            function injectThis() {
                window.location.reload();
            }
            theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
            theDoc.body.appendChild(theScript);
        }
    }

}

const isToday = (someDate) => {
    return moment.tz("Australia/Brisbane").isSame(someDate, 'day');
};

const printPreview = (data, type = 'application/pdf') => {
    let blob = b64toBlob(data, type);
    const blobURL = URL.createObjectURL(blob);
    return blobURL;
};

const b64toBlob = (content, contentType) => {
    contentType = contentType || '';
    const sliceSize = 512;
    // method which converts base64 to binary
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
        type: contentType
    }); // statement which creates the blob
    return blob;
};

/**
 * Generating a click and collect label
 * @param shipment
 * @param parcels
 * @returns {Function}
 */
export function generateCCLabel(shipment,parcels){
    return (dispatch,getState) => {
        let shipmentId = shipment.shipmentId;
        let store = getState().authState.store;
        dispatch(loading(true));
        dispatch(updateShipmentStage('STAGE_THREE',shipmentId));
        dispatch(loading(false));
        dispatch(fetchCCLabel(shipment,parcels,store)).then(response=>{
            if(response.status){
                let label = response.label;
                try{
                    let blob = b64toBlob(label, 'application/pdf');
                    s3.s3Upload(blob,'labels/'+shipmentId+'-label.pdf').then(()=>{
                        let url = printPreview(label);
                        dispatch(setLabels(shipmentId,url));
                        dispatch(updateShipmentStage('STAGE_THREE',shipmentId));
                        dispatch(loading(false));
                    });

                }catch (e) {
                    dispatch(notification.errorNotification('Error creating label! please contact dev support'));
                    dispatch(loading(false));
                    dispatch(updateShipmentStage('STAGE_TWO',shipmentId));
                    console.log(e);
                }
            }else {
                dispatch(notification.errorNotification('Network error! Please try again or contact dev support'));
                dispatch(loading(false));
                dispatch(updateShipmentStage('STAGE_TWO',shipmentId));
            }
        }).catch((e)=>{
            dispatch(notification.errorNotification('Error creating label! please contact dev support'));
            dispatch(loading(false));
            dispatch(updateShipmentStage('STAGE_TWO',shipmentId));
            console.log(e);
        })
    }
};

function checkSession(){
    return (dispatch) => {
        cogLib.checkActiveSession().then(response => {
            dispatch(notification.errorNotification('please refresh the page'));
        });
    }
}
//
// /**
//  * Creating shipment mqtt messages, currently generic and static
//  * @param type
//  * @param shipment
//  * @returns {string}
//  */
// function createShipmentMessage(type,shipment) {
//     switch(type){
//         case "HOLD_SHIPMENT":
//             return "There is an update with the shipment " + shipment.incrementId;
//         case "PENDING_SHIPMENT":
//             return "There is an update with the shipment " + shipment.incrementId;
//         case "ADD_SHIPMENT":
//             return "A new shipment "+ shipment.incrementId + " is ready to be fulfilled"
//     }
// }

/**
 * When an item has a manufacturing fault this function creates a pdf for the store to stick to the item.
 * this pdf contains Date,Ibt,staff till number, sku,
 * @param data
 * @return {string}
 */
function createFaultyItemPdf(data) {
    let {ibt, fulfiller, skus, qty} = data
    let doc = new jsPDF();
    let ibt_number = ibt ? ibt : "not assigned"
    let staff_number = fulfiller ? fulfiller : "unknown"
    let r_codes = skus ? skus.join() : "unknown"
    doc.setFontSize(26);
    doc.text("Date: " + new Date().toLocaleDateString(), 15, 25);
    doc.text("IBT NUMBER: " + ibt_number, 15, 45);
    doc.text("R_CODES: " + r_codes, 15, 65);
    doc.text("Total Units: " + qty, 15, 85);
    doc.text("STAFF NUMBER: " + staff_number, 15, 110);
    let dataURL = doc.output();
    return btoa(dataURL);
}

/**
 * Takes in any number of shipments and returns true if at least one shipment is a high priority shipment
 * (Same Day or CC that is not 'pending_pickup')
 *
 * @param shipments
 * @returns {boolean}
 */
function isPriorityShipment(...shipments) {
    for (let shipment of shipments) {
        if (shipment.method === METHOD.SAME_DAY) {return true;}
        if (shipment.method === METHOD.CLICK_COLLECT && shipment.status !== STATUS.PENDING_PICKUP) {return true;}
    }
    return false;
}

/**
 * Compares two shipments where at least one is a high priority shipment.
 * If both shipments are high priority sort by createdAt date otherwise sort based on the shipment with higher
 * priority.
 *
 * @param shipmentA
 * @param shipmentB
 * @returns {number|number}
 */
function comparePriorityShipment(shipmentA, shipmentB) {

    //Both Shipments are High Priority determine order based on createdAt
    if ((shipmentA.method === METHOD.SAME_DAY || shipmentA.method === METHOD.CLICK_COLLECT) &&
        (shipmentB.method === METHOD.SAME_DAY || shipmentB.method === METHOD.CLICK_COLLECT)) {
        return new Date(shipmentA.createdAt) - new Date(shipmentB.createdAt);
    }

    //At least one of the shipments is high priority. Therefore if shipmentA doesn't contain a high priority method
    //shipmentB is the higher priority shipment.
    return (shipmentA.method !== METHOD.SAME_DAY && shipmentA.method !== METHOD.CLICK_COLLECT) ? 1 : -1;
}

/**
 * Compare two shipments where both shipments are NOT high priority. If shipping methods are the same sort based on
 * createdAt date. Otherwise sort by Express -> Standard -> CC (pending pickup)
 *
 * @param shipmentA
 * @param shipmentB
 * @returns {number}
 */
function compareShipment(shipmentA, shipmentB) {
    //Shipments with Labels printed but incomplete should be up the top
    if (shipmentA.labelsPrinted || shipmentB.labelsPrinted) {
        return (shipmentA.labelsPrinted) ? -1 : 1;
    }

    //Both Shipments have the same method sort by createdAt
    if (shipmentA.method === shipmentB.method) {
        return new Date(shipmentA.createdAt) - new Date(shipmentB.createdAt);
    }

    //Express takes priority over standard
    if (shipmentA.method === METHOD.EXPRESS && shipmentB.method === METHOD.STANDARD) {
        return -1;
    }

    if (shipmentA.method === METHOD.STANDARD && shipmentB.method === METHOD.EXPRESS) {
        return 1;
    }

    //Only Options left are CC (pending_pickup) and Standard/Express. pending_pickup shipments are the lowest priority
    return (shipmentA.status === STATUS.PENDING_PICKUP) ? 1 : -1;
}
