import React, {Component} from "react";
import {connect} from "react-redux";
import {Row,Col,Table} from "react-bootstrap";
import './reportshipping.css'
import {getManifestedStores} from "../../../actions/ReportingActions";
var moment = require('moment-timezone');
const mapStateToProps = state => {
    return {userId:state.authState.username, manifestData:state.reportingState.manifestData}

};

const mapDispatchToProps = dispatch =>{
    return {
        getManifestedStores: (storeId,date)=>{dispatch(getManifestedStores(storeId,date))}
    }
};

class ManifestedStores extends Component {
    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className="manifested">
                <Row>
                    <Col sm={3}>
                        <h2 className={"small-heading-text"}>
                            Manifests
                        </h2>
                    </Col>
                    <Col sm={3}>
                        <button onClick={()=>this.props.getManifestedStores()}>Refresh</button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Table striped hover bordered size="sm" style={{width:'100%'}}>
                            {this.renderManifested()}
                        </Table>
                    </Col>
                </Row>
            </div>
        )
    }

    renderManifested(){
        let manifested = this.props.manifestData;
        let list = manifested.map((store)=>
            <tr className = "reporting-manifested">
                <td>
                    <span>{store?.address?.state}</span>
                </td>
                <td>
                    <span>{store?.name} ({store?.storeId})</span>
                </td>
                {
                    store?.manifest?.date ?
                    <td>
                        <span className={moment.tz("Australia/Brisbane").isSame(store?.manifest?.date,'d') ? "manifest-green":"reporting-manifested manifest-error"}>{moment(store?.manifest?.date).format("DD/MM/YY HH:mm")}</span>
                    </td>:null
                }
            </tr>

        );
        return (
            (manifested.length > 0 ?
                <tbody>
                    {list}
                </tbody>
            :<p>To view stores that have manifested today, please press refresh. If your store has not manifested for today please follow up and contact them</p>)

        )
    }
}

const manifestedStores = connect(mapStateToProps, mapDispatchToProps)(ManifestedStores);
export default manifestedStores;
