import {API} from "aws-amplify/lib";

export const getArchived = (store,date)=> {
    return API.post('shipping','shipping/shipment/rejected/get', {
        body: {
            store,
            date
        }
    });
};

export const generateArchived = (store=null,date=null)=> {
    return API.post('shipping','shipping/shipment/rejected/generate', {
        body: {
            store,
            date
        }
    });
};

export const getManifestedStores = (store=null,date=null,userId)=>{
    return API.post('shipping','shipping/shipment/manifested/get', {
        body: {
            store,
            date,
            userId
        }
    });
};

export const getPendingShipments = (store = null,date = null,userId) =>{
    return API.post('shipping','shipping/shipment/report/get', {
        body: {
            store,
            date,
            userId,
            query:'query-pending'
        }
    });
};

export const generatePendingOlderShipments = (store = null,date = null,userId) =>{
    return API.post('shipping','shipping/shipment/report/get', {
        body: {
            store,
            date,
            userId,
            query:'generate-older'
        }
    });
};

export const generateCompleteShipments = (store = null,date = null,userId) =>{
    return API.post('shipping','shipping/shipment/report/get', {
        body: {
            store,
            date,
            userId,
            query:'generate-complete'
        }
    });
};

export const getCompleteShipments = (store = null,date = null,userId) =>{
    return API.post('shipping','shipping/shipment/report/get', {
        body: {
            store,
            date,
            userId,
            query:'query-complete'
        }
    });
};

export const getRejectedShipments = (store = null,date = null,userId) =>{
    return API.post('shipping','shipping/shipment/report/get', {
        body: {
            store,
            date,
            userId,
            query:'query-rejected'
        }
    });
};
/**
 *
 * @param method 'ship' or 'collect'
 * @param store
 * @param date
 * @param status
 * @param userId
 * @returns {Promise<any>}
 */
export const getAllStoreView = (method,store,date,status,userId,allStores=[],myStores=[]) => {
        return API.post('shipping','shipping/shipment/report/get', {
            body: {
                method,
                store,
                date,
                userId,
                allStores: JSON.stringify(allStores),
                myStores: JSON.stringify(myStores),
                query:'query-all'
            }
        })
};
