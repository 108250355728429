import React from 'react';
import { connect } from 'react-redux';
import withReducer from "./WithAuth"

/**
 * Checks Permission arrays to see if user has any permissions that match the allowed permissions
 */
const checkPermissions = ( userPermissions, allowedPermissions, role, allowedRoles ) => {
    // if user permissions have been retrieved from redux store
    if(userPermissions){
        // if no permissions required to access
        if (allowedRoles.length > 0) {
            return allowedRoles.includes(role);
        } else if (allowedPermissions.length === 0) {
            return true;
        }
        return userPermissions.some(permission =>
            allowedPermissions.includes(permission)
        );
    }
};

/**
 * where userPermissions are retrieved from the redux store, allowed Permissions are passed through as an array of text, children is the component to show
 * renderNoAccess is what to display if there is no Access
 * @param userPermissions
 * @param allowedPermissions
 * @param children
 * @param renderNoAccess
 * @param role
 * @param allowedRoles
 * @returns {null|*}
 * @constructor
 */
const Permissions = ({userPermissions, allowedPermissions, children, renderNoAccess, role, allowedRoles }) => {
    const permitted = checkPermissions(userPermissions, allowedPermissions, role,allowedRoles);
    if (permitted) {
        return children;
    }
    return null;
};

Permissions.defaultProps = {
    allowedPermissions: [],
    userPermissions: [],
    role: null,
    allowedRoles: [],
    renderNoAccess: () => null,
};

// Compose AccessControl component with redux
export default connect(state => ({
    userPermissions: state.authState.userPermissions,
    role:state.authState.role
}))(Permissions);
