import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import Routes from "./Routes";
import {Auth,Hub} from 'aws-amplify';
import './components/Nav.css'
import Typekit from 'react-typekit';
import ReactGA from 'react-ga';
import {logoutUser} from "./store/ReduxStore";
import './App.css';
import { ToastContainer } from "react-toastify";
import NewSidebar from "./containers/sidebar/NewSidebar"

import {authorize,loginUser,idleUser} from "./actions/AuthActions";
import {connect} from "react-redux";

var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
const mql = window.matchMedia(`(min-width: 800px)`);

ReactGA.initialize('UA-5477132-6');
ReactGA.pageview('/');

/*mapping current users basic information and permissions to Redux*/
const mapStateToProps = state => {
    return { user: state.authState };
};
/*retrieving current users basic information and permissions from Redux*/
const mapDispatchToProps = dispatch => {
    return {
        authorize : () => dispatch(authorize()),
        loginUser : (email) => dispatch(loginUser(email)),
        idleUser : () => dispatch(idleUser())
    };
};

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            pageType: "",
            sidebarOpen: true,
            sidebarDocked: true,
            awsCreds : null,
            open : false,
            isMobile:false,
            noOfTasks:0,
            user:{},
            timeoutID:0
        };
        this.props.history.listen(location => {
                ReactGA.pageview(location.pathname)
            }
        );
    }

    async componentDidMount(){
        // listening for changes in screen change
        mql.addListener(this.mediaQueryChanged);
        // Listen for authentication changes. If the user logs in or out, update the state.
        Hub.listen('auth', ({ payload: { event, data } }) => {
        	console.log(event, data);
        	switch (event) {
        		case 'signIn':
        			this.authenticateUser();
        			Auth.currentAuthenticatedUser().then((userData) => {
        			    console.log(userData);
        			});
        			break;
        		case 'signOut':
            		logoutUser();
            		this.setState({ isAuthenticated: false });
            		this.props.history.push("/login");
        			break;
        	}
        });
        await this.authenticateUser();
    }

    componentWillUnmount() {
        // listening for changes in screen change
        mql.removeListener(this.mediaQueryChanged);
    }

    authenticateUser = async () => {
        try {
            let parent = this;
            await Auth.currentUserCredentials().then(
                credentials => {
                    this.setState({awsCreds: credentials});
                    this.setState({isAuthenticating: false})
                    parent.props.loginUser(true);
                    parent.userHasAuthenticated();
                    this.props.history.push('/')
                }
            );
        }catch (e) {
            this.setState({isAuthenticating: false})
        }
    }

    mediaQueryChanged  = () => {
        this.setState({ isMobile:!mql.matches});
    };

    onSetOpen = (open) => {
        this.setState({ open: open });
        if(!open){
            this.setState({sidebarDocked:true})
        }
        else{
            this.setState({sidebarDocked:false})
        }
    };

    userHasAuthenticated = () =>{
        this.props.authorize();
        this.setState({ isAuthenticated: true });
    };

    loggedIn = async () =>{
        let user =await  Auth.currentAuthenticatedUser();
        if(user.attributes.email_verified){
            console.log('email verified');
            this.props.loginUser();
        }else{
            console.log('not verified')
            this.props.loginUser(false);
        }

        this.setState({ isAuthenticated: true });
    };

    // can not be async,
    handleLogout = async () => {
            await Auth.signOut();
            this.setState({ isAuthenticated: false });
            logoutUser();
            this.props.history.push("/login");
    };

    openMenu =(e) =>{
        this.setState({sidebarDocked:!e,open:e})
    };

    handleNoOfTasks = (tasks) =>{
        if(tasks){
            this.setState({noOfTasks:tasks});
        }
    };

    render() {
        /* props to send through to all routes */
        const childProps = {
            user: this.props.user,
            isAuthenticated: this.state.isAuthenticated,
        };
        return (

            !this.state.isAuthenticating &&
            <div className={"App"}>
                <Typekit kitId="dhl2fte" />
                {this.props.user.status === "LOGGED_IN" && this.props.user.role !==null && this.props.user.cognitoInfo !==null  ?
                        <div className="app-wrapper logged=in">
                            <div id="sidebar">
                              <NewSidebar handleLogout = {this.handleLogout}/>
                            </div>
                            <div id={"App"}>
                                <Routes childProps={childProps} />
                                <ToastContainer  />
                            </div>
                        </div>
                    : this.props.user.status === "LOGGED_OUT" ?
                    <Routes childProps={childProps} />
                        : <p>Loading...</p>
                }
                {/*<Splash />*/}
            </div>
        );
    }
}
const AppPage = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(AppPage);

