import * as type from "../constants/actions/ActionTypes";

const initialState = {
    status: "LOGGED_OUT",
    username: null,
    JWT: null,
    userPermissions:[],
    myStores:[],
    role:null,
    roleStatus: null,
    hardware:null,
    cognitoInfo : null,
    store:null,
    website_id: null,
    verified : false
};
export default function authReducer (state = initialState , action)  {
    switch (action.type) {
        case type.AUTHORISE_USER:
            return {
                ...state, 
                username:action.user.username, 
                JWT: action.user.JWT,
                userPermissions:state.userPermissions.concat(action.user.userPermissions),
                role:action.user.role,
                roleStatus: action.user.status,
                myStores:state.myStores.concat(action.user.myStores),
            };
        case type.AUTHORISE_STORE:
            return {
                ...state,
                store:action.store.store,
                username:action.store.username,
                JWT: action.store.JWT,
                userPermissions:state.userPermissions.concat(action.store.userPermissions),
                role:action.store.role,
                roleStatus: action.store.status,
                store_view_code: action.store.store_view_code,
                hardware:action.store.hardware,
                myStores:[action.store.store],
            };
        case type.LOGIN_USER:
            return {...state, status: action.status, verified : action.email};
        case type.LOAD_COGNITO_INFO:
            return {...state, cognitoInfo: action.cognitoInfo,identityId: action.identityId};
        case type.UPDATE_ROLE_USER:
            return {...state,roleStatus:action.payload}
        case type.IDLE_USER:
            return {...state, status: action.status};
        default:
            return state;
    }
};

