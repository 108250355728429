import { gql } from "@apollo/client";

export const GET_CART = (cart_id) => {
    return gql`
          query {
                cart(cart_id: "${cart_id}") {
                    items {
                        uid
                        ... on ConfigurableCartItem {
                            configurable_options {
                                option_label
                                value_label
                            }
                            simple_sku
                            supplier
                            serialized_discount_amounts
                        }
                        product {
                            name
                            sku
                            special_price
                            media_gallery {
                              url
                            }
                        }
                        prices {
                            row_total {
                                value
                            }
                            total_item_discount {
                                value
                            }
                        }
                        quantity
                    }
                    prices {
                        grand_total {
                            value
                        }
                    }
                    serialized_discount_amounts
                }
          }
    `;
}

export const GET_PRODUCT = (sku) => {
    return gql`
        query {
            products(filter: { sku: { eq: "${sku}"}}) {
                items {
                    sku
                    ... on ConfigurableProduct {
                        variants {
                            product {
                                sku
                            }
                            attributes {
                                uid
                            }
                        }
                    }
                }
            }
        }
    `;
}

/**
 * Query string to retrieve the configurable product's name along with its simple products.
 * The configurable product's SKU has been used to identify it and only the name, SKU, stock status (for both config and simples),
 * UID and the special price value of the products are being retrieved.
 *
 * @param skus
 */
export const GET_CONFIGURABLE_PRODUCTS = (skus) => {
    return gql`
        query {
            products(filter: {sku: {in: [${skus}]}}) {
                items {
                    name
                    sku
                    stock_status
                    price_range {
                        minimum_price {
                            regular_price {
                                value
                            }
                        }
                    }
                    special_price
                    ... on ConfigurableProduct {
                        variants {
                            product {
                                sku
                                stock_status
                                uid
                            }
                            attributes {
                                label
                                code
                            }
                        }
                    }
                }
            }
        }
    `;
}