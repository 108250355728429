import React, {Component} from "react";
import HamburgerMenu from "../../images/menu.png"
import SocialIcon from "../../images/Social-Icon.jpg"
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import {API, Auth} from "aws-amplify/lib/index";
import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';
import AddIcon from "../../components/icons/icon-add"
import AppIcon from "../../components/icons/icon-applicants";
import LogoutIcon from "../../components/icons/icon-logout";
import OfferIcon from "../../components/icons/icon-offer"
import ShopIcon from "../../components/icons/icon-shop"
import TaskIcon from "../../components/icons/icon-tasks"
import StoreIcon from "../../components/icons/icon-std"
import ChristmasIcon from "../../components/icons/icon-christmas"
import ShippingIcon from "../../components/icons/icon-ship"
import LearningIcon from "../../components/icons/icon-learning";
import {connect} from "react-redux";
import './newsidebar.css'
import {loadRolesFromStore} from "../../actions/RolesActions";


const mapStateToProps = state => {
    return {username:state.authState.username,jwt : state.authState.JWT, roleStatus: state.authState.roleStatus, role:state.authState.role, permissions: state.authState.userPermissions };
};



class NavContent extends Component{
    constructor(props){
        super(props);
        this.teamMembers = ["TEAM_MEMBER","DC_TEAM_MEMBER"];
        this.managers = ["WAREHOUSE_MANAGER","MANAGER","ASM",];
        this.admins = ["HR","SUPERUSER","PAYROLL"];
        this.storeRoles = ["STORE"];
        this.permissions = this.props.permissions;
        this.state = {
            isOffer:false,
            noOfTasks: 0,
            headOffice : []
        }
    }
    //TODO take to redux soon
    getStatus = async username =>{
        return await API.get("users", `users/user/personalatts/${username}`);
    };
    async componentDidMount() {
        await this.getStatus(this.props.username).then(response => {
            if(response.app_offer ==='false' || response.app_offer ==='true'){
                this.setState({isOffer:true})
            }
        })
        let headOffice = await loadRolesFromStore('HEAD_OFFICE');
        this.setState({
            headOffice
        });

    }

    openNav() {
        document.getElementById("mySidenav").classList.add("open-nav");
        document.getElementById("App").classList.add("app-open-nav");
        document.getElementById("sidenav-overlay").style.width = "100%";
    }

    closeNav() {
        document.getElementById("mySidenav").classList.remove("open-nav");
        document.getElementById("App").classList.remove("app-open-nav");
        document.getElementById("sidenav-overlay").style.width = "0";
        document.body.style.backgroundColor = "white";
    }
    render(){
        return(
    <div>
        <div className="fixed-sidenav">
            <img src= {HamburgerMenu} className="hamburger-menu" onClick={this.openNav}></img>
            <div id="sidebarfooter">
                <div className="footer-links-mobile">
                    <a style={{color: "white"}} href="mailto:devsupport@universalstore.com.au?subject=Team Portal Help">&nbsp;Help&nbsp;</a>
                    <Link to='/privacy' style={{color: "white"}}>Privacy</Link>
                    <a style={{fontSize:"9px"}} >© Universal Store Pty Ltd</a>
                </div>
            </div>
            <img id="sidenavlogo" src={SocialIcon}></img>
        </div>
        <div>
            <div id="mySidenav" className="sidenav">
                <div className="side-bar-icons">
                    {
                        this.permissions.includes("shipping::sfs") ?
                            <div>
                                <LinkContainer to="/ship" onClick = {this.closeNav}>
                                <div className="sidebar-item">
                                    <ShippingIcon className="icon"/>
                                    <a className="label-text">Orders</a>
                                </div>
                                 </LinkContainer>
                            </div>
                            :null
                    }
                    {
                        this.permissions.includes("shipping::cc") ?
                            <div>
                                <LinkContainer to="/collect" onClick = {this.closeNav}>
                                    <div className="sidebar-item">
                                        <ShopIcon className="icon"/>
                                        <a className="label-text">Collect</a>
                                    </div>
                                </LinkContainer>
                            </div>
                            :null
                    }
                    {
                        this.permissions.includes("shipping::s2d") ?
                            <div>
                                <LinkContainer to="/order" onClick = {this.closeNav}>
                                    <div className="sidebar-item">
                                        <StoreIcon className="icon"/>
                                        <a className="label-text">Catalog</a>
                                    </div>
                                </LinkContainer>
                            </div>
                            :null
                    }
                    {
                        this.permissions.includes("reporting::full") || this.permissions.includes("reporting::store") ?
                            <div>
                                <LinkContainer to="/reporting" onClick = {this.closeNav}>
                                    <div className="sidebar-item">
                                        <TaskIcon className="icon"/>
                                        <a className="label-text">Reporting</a>
                                    </div>
                                </LinkContainer>
                            </div>
                            :null
                    }
                    {
                        this.props.role !== "STORE" ?
                        	<div className="sidebar-item"  onClick={()=> window.open("https://universalstore.auth.ap-southeast-2.amazoncognito.com/login?client_id=8cn5t719r68ljvs5gl2cr5lp9&response_type=code&scope=email+openid&redirect_uri=https://universalstore.myabsorb.com.au/", "_blank")}>
                        		<LearningIcon className="icon"/>
                        		<a href="https://universalstore.auth.ap-southeast-2.amazoncognito.com/login?client_id=8cn5t719r68ljvs5gl2cr5lp9&response_type=code&scope=email+openid&redirect_uri=https://universalstore.myabsorb.com.au/">&nbsp;eLearning&nbsp;</a>
                        	</div>

                        :null
                    }
                    <div className="sidebar-item logout-item" onClick={this.props.handleLogout}>
                        <LogoutIcon className="icon"/>
                        <a className="label-text">Logout</a>
                    </div>
                </div>
            </div>
            <div id="sidenav-overlay" onClick={this.closeNav} />
        </div>

    </div>
        )
    }
}

export default connect(mapStateToProps,null)(NavContent);
