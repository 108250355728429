import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import {authorize, loginUser} from "../../actions/AuthActions";
import {signIn} from "../../actions/login/LoginActions"
import {connect} from "react-redux";
import "./login.scss"

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoaderButton from "../../components/LoaderButton";

const mapStateToProps = state => {
    return {
        user : state.authState
    }
}
const mapDispatchToProps = dispatch => {
    return {
        authorize : (session) => dispatch(authorize(session)),
        loginUser : () => dispatch(loginUser())
    };
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:null,
            password:null,
            NotAuthorizedException:false,
            UserNotFoundException: false,
            NEW_PASSWORD_REQUIRED: false,
            LOGGED_IN:false,
            loading:false
        };
    }

    handleLogin = async() =>{
        this.setState({loading:true})
        await signIn()
    }

    renderLogin() {
        return (
            <div className="login-wrapper">
            	<div className="logo">
            		<img className="login-logo" src = 'https://www.universalstore.com/static/version1685995624/frontend/universalstore/universalstore/default/images/logo.svg'></img>
            	</div>
          		<Col sm={4}>
            		<LoaderButton
            			className={"btn-block"}
            			variant="dark"
            			type="submit"
            			text={"LOGIN"}
            			isLoading ={this.props.loading}
            			onClick={this.handleLogin}
            		/>
          		</Col>
            	<p style={{color:'white',marginTop:'10px',fontSize:'14px',width:'280px'}} className = "privacy-info"> By entering Universal Store's Team Portal, I agree to the <Link to='/privacy'>privacy policy</Link>, and allow Universal Store to use and store the information I provide for employment and recruitment purposes.</p>
            	<p style={{color:'white',marginTop:'10px',fontSize:'14px'}}><a href="mailto:devsupport@universalstore.com.au">Need Help?</a></p>
            </div>
        );
    }
    render() {
        return (
            <div className="signup">
            	{ this.renderLogin() }
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Login));
