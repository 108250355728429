import React, { Component } from "react";
import { API } from "aws-amplify";
import "./Home.css";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom"
import {connect} from "react-redux";


/*mapping current users basic information and permissions to Redux*/
const mapStateToProps = state => {
    return { user: state.authState, role: state.authState.role, cogInfo:state.authState.cognitoInfo };
};


class Home extends Component {
    constructor(props) {
        super(props);
       // NotificationClass = new Notification();
        this.handler = this.handler.bind(this)
        this.state = {
            isLoading: true,
            currUser:{},
            notification:'',
            awsCreds : [],
            userRole:{}
        };
    }
    handler(e) {
        e.preventDefault();
        this.setState({
            notification: true
        })
    }

    async componentDidMount() {
        if (this.props.role === "STORE"){
            this.props.history.push('/ship')
        } else if(this.props.user.status === "LOGGED_OUT"){
            this.props.history.push('/login')
        }
    }

    renderLoading(){
        return(
            !this.state.isLoading 
            ? <div>
                loading your view... If page does not load, please refresh window.
            </div>

            : <div>
            	<div className="logo" style={{background: "black", padding: "20px"}} >
            		<img className="login-logo" src = 'https://www.universalstore.com/static/version1685995624/frontend/universalstore/universalstore/default/images/logo.svg'></img>
            	</div>
            	<h1>
            		Welcome to the Universal Store Team Portal
            	</h1>
            	<p>
            	    Select a link from the sidebar to get started.
            	</p>
            </div>
        )
    }

    render() {

        return (
            <div className="Home">
                {this.renderLoading()}
            </div>
        );
    }
}


const HomePage =  connect(mapStateToProps,null)(Home);
export default withRouter(HomePage);
